import React from 'react'
import Nav from '../../Components/Nav'
import {Card} from 'primereact/card'
export default function Sellpage() {
  return (
    <div>
        <Nav />
        
    </div>
  )
}
