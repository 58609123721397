import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { Card } from 'primereact/card'

export default function Importpage() {

    const [palletList, setPalletList] = useState([])
    const [selectedPalletList, setSelectedPalletList] = useState([])

    const [selectedLotList, setSelectedLotList] = useState([])
    const [dupeList, setDupeList] = useState([])
    const [palletedLots, setPalletedLots] = useState(
        [
            {
                palletNumber: 'pal-0001',
                lots: ['lot-0001', 'lot-0002', 'lot-0003']
            }
        ]
    )
    
    //tab will determine which list to pull lot from. 
    //0 == dupes
    //1 == palletedLots
    const [tab, setTab] = useState(1)


    function selectPallet(index, select) {
        if (select) {
            // axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/pallet/getLots', { palletNumber:  })

            setSelectedPalletList([...selectedPalletList, palletList[index]]);
            setPalletList(palletList.filter((pallet, i) => i !== index));
        }else{
            setPalletList([...palletList, selectedPalletList[index]]);
            setSelectedPalletList(selectedPalletList.filter((pallet, i) => i !== index));
        }
    }

    function selectLot(index, tab = null) {
        // tab will determine which list the lot is going back to if it is null, it is being moved to selectedLotList
        if (tab==0) {
            setSelectedLotList([...selectedLotList, dupeList[index]]);
            setDupeList(dupeList.filter((lot, i) => i !== index));
        }
        if (tab==1) {
            setSelectedLotList([...selectedLotList, palletedLots[index]]);
            setPalletedLots(palletedLots.filter((lot, i) => i !== index));
        }
        if (tab==null) {
            setDupeList([...dupeList, selectedLotList[index]]);
            setSelectedLotList(selectedLotList.filter((lot, i) => i !== index));
        }
    }
         


    


    useEffect(() => {
        //get pallets with items on it
        axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/pallet/get').then((response) => {
            setPalletList(response.data);
        }).catch((error) => {
            console.log(error);
        })

        
    }, [])


    return (
        <div className='flex flex-column w-full'>
            {/* pallets */}
            <div className='flex w-full p-4'>
                <div className='flex w-6 flex-column overflow-y-scroll'>
                    {
                        palletList.map((pallet, index) => {
                            return (
                                <div onClick={()=>{selectPallet(index, true)}}>
                                    {pallet}
                                </div>
                            )
                        })
                        
                    }
                </div>
                <div className='flex w-6 flex-column overflow-y-scroll'>
                    {
                        selectedPalletList.map((pallet, index) => {
                            return (
                                <div onClick={()=>{selectPallet(index, false)}}>
                                    {pallet}
                                </div>
                            )
                        })
                        
                    }
                </div>
            </div>
            {/* lots */}
            <div className='flex'>
                <div className='flex flex-column overflow-y-scroll'>
                    {
                        palletedLots.map((pallet, index) => {
                            return (
                                <Card title={pallet.palletNumber}>
                                    {
                                        pallet.lots.map((lot, index) => {
                                            return (
                                                <div onClick={()=>{selectLot(index, 1)}}>
                                                    {lot}
                                                </div>
                                            )
                                        })
                                    }
                                </Card>
                            )
                        })
                        
                    }
                </div>
                <div className='flex flex-column overflow-y-scroll'>
                    {
                        selectedLotList.map((lot, index) => {
                            return (
                                <div onClick={()=>{selectLot(index, false)}}>
                                    {lot}
                                </div>
                            )
                        })
                        
                    }
                </div>
            </div>
        </div>
    )
}
