import { useState, useEffect } from "react";
import axios from 'axios';

const usePallet = (pallet) => {
    //check if no pallet
    
    if (pallet == '') {
        return false;
    }
    if (pallet == 'House') { // temporary system for auction house uploads
        return pallet;
    }
    if (/pal-/i.test(pallet)) {
        if (pallet.length == 8) {
            return pallet;
        }else{
            return false;
        }
    }else{
        if (pallet.length == 4 && /^\d+$/.test(pallet)) {
            return 'pal-'+pallet;
        }else{
            return false;
        }
    }
};

const useLot = (lot, setConsignor) => {
    if (/lot-/i.test(lot)) {
        if (setConsignor != undefined) {
            if (lot.length == 17 && lot.split('-').length > 2) {
                setConsignor(lot.split('-')[2]); // set consignor to state defined in useConsignor
                return 'lot-'+lot.split('-')[1];
            }
        }else{
            if (lot.length == 17 && lot.split('-').length > 2) {
                return 'lot-'+lot.split('-')[1];
            }
        }
        
        if (lot.length == 10) {
            return lot;
        }else{
            return false;
        }
    }else{
        if (lot.length == 6 && /^\d+$/.test(lot)) {
            return 'lot-'+lot;
        }else{
            return false;
        }
    }
};

const useDim = (dim) => {
    //parses codes like "dim-00" or "dim-0"
    if (/dim-/i.test(dim)) {
        return dim.split('-')[1].toString();
    }else{
        return false;
    }
}

const useWgt = (wgt) => {
    //parses codes like "wgt-00" or "wgt-0"
    if (/wgt-/i.test(wgt)) {
        return wgt.split('-')[1].toString();
    }else{
        return false;
    }
}

const useLoc = (loc) => {
    if (/loc-/i.test(loc)) {
        if (loc.length == 9) {
            return loc;
        }else{
            return false;
        }
    }else{
        if (loc.length == 5 && !/^\d+$/.test(loc)) {
            return 'loc-'+loc;
        }else{
            return false;
        }
    }
};


function loadImage(img) {
    return new Promise((resolve, reject) => {
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = function (error) {
        reject(error);
      };
    });
  }


const setCookie = (name, value, days) => {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);

    const cookieValue = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieValue;
};

const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }

    return null;
};

async function AdjustImages(imageUrls) {
    const adjustedImages = [];
  
    // Define the target aspect ratio (4:3)
    const targetAspectRatio = 4 / 3;
  
    // Define the padding color (white)
    const paddingColor = 'white';
  
    // Loop through the array of image URLs
    for (let i = 0; i < imageUrls.length; i++) {
      const img = new Image();
      img.src = imageUrls[i];
  
      const imageData = await loadImage(img);
  
      const currentAspectRatio = imageData.width / imageData.height;
  
      // Check if the current image needs padding
      if (currentAspectRatio !== targetAspectRatio) {
        let paddedWidth, paddedHeight;
  
        if (currentAspectRatio > targetAspectRatio) {
          // Add padding to the sides
          paddedWidth = imageData.width;
          paddedHeight = imageData.width / targetAspectRatio;
        } else {
          // Add padding to the top and bottom
          paddedWidth = imageData.height * targetAspectRatio;
          paddedHeight = imageData.height;
        }
  
        // Create a canvas element to draw the padded image
        const canvas = document.createElement('canvas');
        canvas.width = paddedWidth;
        canvas.height = paddedHeight;
  
        const ctx = canvas.getContext('2d');
  
        // Fill the canvas with white
        ctx.fillStyle = paddingColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
  
        // Calculate the position to center the image
        const offsetX = (paddedWidth - imageData.width) / 2;
        const offsetY = (paddedHeight - imageData.height) / 2;
  
        // Draw the image on the canvas with padding
        ctx.drawImage(img, offsetX, offsetY, imageData.width, imageData.height);
  
        // Convert the canvas to a Base64 string
        const paddedImageBase64 = canvas.toDataURL('image/jpeg'); // You can change the format if needed
  
        // Push the adjusted image as a Base64 string to the result array
        adjustedImages.push(paddedImageBase64);
      } else {
        // If the image already has the correct aspect ratio, push the original Base64 string
        adjustedImages.push(imageUrls[i]);
      }
    }
  
    return adjustedImages;
  }



function openFullscreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

const selectInput = (input) => {
    switch (input) {
        case 0: return '#palletInput';
        case 5: return '#lotInput';
        case 6: return '#upcInput';
    }
}


function speak(text, rate=2) {
    axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/tts', {
        params: {
        text: text,
        rate: rate
        }
    })
    .then(response => {
        const audioDataUri = response.data;
        const audio = new Audio(audioDataUri);
        audio.play();
    })
    .catch(error => {
        // console.error("Error fetching audio:", error);
    });
}

function addLotToCart(lot) {
    let cart = getCookie('5DACart');
    
    if (cart == null) {
      cart = [];
    } else {
      cart = JSON.parse(cart);
    }
  
    // Check for duplicates before pushing the lot
    if (!cart.includes(lot) && lot != null) {
      cart.push(lot);
      setCookie('5DACart', JSON.stringify(cart), 7);
    }
  }

  function addLotToFavorites(lot) {
    let favorites = getCookie('5DAFavorites');

    if (favorites == null) {
      favorites = [];
    } else {
      favorites = JSON.parse(favorites);
    }

    // Check for duplicates before pushing the lot
    if (!favorites.includes(lot) && lot != null) {
      favorites.push(lot);
      setCookie('5DAFavorites', JSON.stringify(favorites), 7);
    }
   
  }

  function removeLotFromFavorites(lot) {
    let favorites = getCookie('5DAFavorites');
    
    if (favorites == null) {
      favorites = [];
    } else {
      favorites = JSON.parse(favorites);
    }
  
    // Check for duplicates before pushing the lot
    if (favorites.includes(lot) && lot != null) {
      favorites.splice(favorites.indexOf(lot), 1);
      setCookie('5DAFavorites', JSON.stringify(favorites), 7);
    }
  }

  function removeLotFromCart(lot) {
    let cart = getCookie('5DACart');
    
    if (cart == null) {
      cart = [];
    } else {
      cart = JSON.parse(cart);
    }
  
    // Check for duplicates before pushing the lot
    if (cart.includes(lot) && lot != null) {
      cart.splice(cart.indexOf(lot), 1);
      setCookie('5DACart', JSON.stringify(cart), 7);
    }
  }

// get lot async
const getLot = (lotNumber) => {
    return new Promise((resolve, reject) => {
      axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/get?lotNumber=' + lotNumber)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }
  
  const queryLot = (query) => {
    return new Promise((resolve, reject) => {
      axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/search', {
        params: {
          searchQuery: query,
        }
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => reject(error));
    });
  }
    

const useLib = {
    parsePallet: usePallet,
    parseLot: useLot,
    parseLoc: useLoc,
    selectInput: selectInput,
    openFullscreen: openFullscreen,
    speak: speak,
    AdjustImages: AdjustImages,
    parseDim: useDim,
    parseWgt: useWgt,
    setCookie: setCookie,
    getCookie: getCookie,
    addLotToCart: addLotToCart,
    removeLotFromCart: removeLotFromCart,
    addLotToFavorites: addLotToFavorites,
    removeLotFromFavorites: removeLotFromFavorites,
    getLot: getLot,
    queryLot: queryLot
}

export default useLib;