import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
export default function Locationpage() {
    const [lot, setLot] = useState('');
    const [locations, setLocations] = useState([]);
    const [images, setImages] = useState([]);
    const [imagesInput, setImagesInput] = useState('');
    useEffect(() => {
        axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/locationList').then((res) => {
            console.log(res.data);
            setLocations(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }, [])
  return (
    <div className='w-full flex flex-column gap-1'>
      <div className='p-input-group'>
        <InputText onChange={(e) => setLot(e.target.value)} />
        <Button onClick={() => {
        axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/repair', {lotNumber: lot}).then((res) => {
          console.log(res.data);
        }).catch((err) => {
          console.log(err);
        });
      }} label='repair test' />
      </div>
      
      <InputText onChange={(e)=>setImagesInput(e.target.value)} />
      <Button label='Parse Image Array' onClick={()=>{
        setImages(JSON.parse(imagesInput));
      }} />
      {
        images.map((image) => {
          return (
            <img className='w-10rem h-10rem' src={image} />
          )
        })
      }
      <div className='grid column-gap-2 w-screen justify-content-center'>
        { 
            locations.map((loc) => {
              return (
                <div className='flex gap-2 w-12rem'>
                    <div>☐ {loc.lot}</div>
                    <div>{loc.location}</div>
                </div>
              )
                
            })
        }
      </div>
    </div>
  )
}
