import './Homepage.css';
import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import {Tag} from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
        
import logo from '../../Assets/Logo.webp';


import Nav from '../../Components/Nav';

function Homepage() {
  const [products, setProducts] = useState([]);

  const [email, setEmail] = useState('')
  const toast = useRef(null);

  const showToast = (message, severity) => {
    if (severity === "success") {
      toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
    } else if (severity === "error") {
      toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
    }
  };

  const isOpenNow = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const currentHour = currentDate.getHours();

    const isWeekday = currentDay >= 1 && currentDay <= 5;
    const isBusinessHours = isWeekday && currentHour >= 9 && currentHour < 17;

    const isSaturday = currentDay === 6;
    const isSaturdayHours = isSaturday && currentHour >= 9 && currentHour < 13;

    const isHolidaySeason =
      (currentDate.getMonth() === 11 && currentDate.getDate() >= 24) ||
      (currentDate.getMonth() === 10 && currentDay === 4 && currentDate.getDate() >= 22);

    return isBusinessHours || isSaturdayHours
      ? 'OPEN NOW'
      : isHolidaySeason
      ? 'Holiday Season (check with us)'
      : 'Currently Closed';
  };

  const status = isOpenNow();

  useEffect(() => {


    axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/search', {
      params: {
        search: '',
        searchQuery: { dupe: false },
        searchSort: { updatedAt: -1 },
        count: 90
      }
    }).then(response => {
      setProducts(response.data);
    }).catch(error => {
      console.error(error);
    });

    if (window.location.href.includes('https://fivedollarauctions-c010b27bf793.herokuapp.com/')) {
      window.location.href = 'https://5dollarauctions.com/upload'
    }

    animateScroll(); // Start initial animation

    const intervalId = setInterval(animateScroll, 10); // Adjust the interval as needed

    return () => {
      clearInterval(intervalId);
      cancelAnimationFrame(animateScroll);
    };
  
  }, [])

  const animateScroll = () => {
    const container = document.getElementById('homepageProductContainer'); // Replace 'productContainer' with the actual ID of your scroll container
    if (container) {
      container.scrollLeft += 1; // Adjust the scroll speed as needed

      // Check if the scroll reached the end, then reset
      if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
        container.scrollLeft = 0;
      }
    }
  };

  
  return (
    <div className='flex flex-column w-full'>
      <Nav />
      <Toast ref={toast} />
      
      
      <main className="surface-ground align-items-between">
        <div className='flex flex-column w-full'>
          <h1 className='ml-4  md:ml-6 lg:ml-8'>Recent Auction</h1>

          <div id="homepageProductContainer" className="flex justify-content-evenly gap-2 bg-white shadow-3 overflow-hidden overflow-x-scroll">
            {products.map((product, index) => (
              <div
                className={`xl:col-2 lg:col-3 md:col-4 sm:col-6 col-6  product-item`}
                key={product._id}
              >
                <div className="w-full border-round surface-0 flex flex-column gap-2">
                  <div className='w-full border-round' style={{ aspectRatio: '4/3', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                    <img alt={index} className='max-w-full max-h-full object-cover' style={{ objectFit: 'cover' }} src={product.images[0]} />
                  </div>
                  <div className='p-flex p-flex-column' style={{ height: '4rem' }}>
                    <span className='p-text-overflow-ellipsis p-text-truncate sm:text-auto text-xs' style={{ display: 'block', overflow: 'hidden', maxHeight: '2.9em', WebkitBoxOrient: 'vertical' }}>
                      
                      {/* max length on product title of 20 characters */}
                      {product.title.length > 50 ? product.title.substring(0, 50) + '...' : product.title}
                    </span>
                    <span className='text-700 text-sm'>{product.lotNumber}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <a className='button flex text-2xl m-5 justify-content-center' href='https://bid.5dollarauctions.com'>
            View All Auctions
          </a>
        </div>
        
        
        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
          <div className="flex flex-wrap align-items-center">
              <div className="w-full lg:w-6 lg:pr-6">
                  <div className="text-900 font-bold text-2xl mb-3">Get our weekly updates and <span className="text-blue-500">Great Deals!</span></div>
                  <div className="text-600 text-xl line-height-3 lg:mb-0 mb-4" style={{ maxWidth: '450px' }}>Join our community to get weekly updates and news!</div>
              </div>

              <div className="w-full lg:w-6">
                  <div className="flex align-items-start md:align-items-center lg:justify-content-end">
                      <InputText onChange={(e)=>{
                        setEmail(e.target.value)
                      }} placeholder="Enter your email" className="p-3 sm:w-6 w-8 mr-3 border-400 mb-3 md:mb-0" />
                      <Button label="Join" severity='success' onClick={()=>{axios.get('https://gavelbaseserver.herokuapp.com/api/addNewsletter?email='+email).then((res)=>{
                        showToast('You have been added to our newsletter!', 'success')
                      }).catch((err)=>{
                        showToast('This email is already on our list!', 'error')
                      })}} className="py-3 font-bold" />
                  </div>
              </div>
          </div>
      </div>
      </main>
    



      <div className='flex lg:flex-row flex-column align-items-center lg:gap-3  p-3 lg:p-8 w-full justify-content-around'>
        <div className='flex flex-column mb-5'>
          <h1 className='text-900 text-5xl flex align-items-center justify-content-start sm:justify-content-center gap-2 '>
            Find Us Locally
          </h1>
          <span className='text-xl'>We are located in the Prescott area, near West branch with more locations to come!</span>
          <p className='text-700 text-xl'><i className="pi pi-map-marker text-2xl"></i> 2477 East Greenwood Rd, Prescott Michigan</p>
          <span className='text-900 text-3xl my-2'>Hours <Tag severity={status === 'OPEN NOW' ? 'success' : status === 'Currently Closed' ? 'danger' : 'warning'} className='text-lg' value={status}></Tag></span>
          <span className='text-600'>Monday - Friday 9am - 5pm</span>
          <span className='text-600'>Saturday 9am - 1pm</span>
          <span className='text-600'>Sunday - Closed</span>
          
        </div>
        <div className='lg:w-6 lg:h-30rem h-20rem w-full'>
          <iframe
            width="100%"
            height="100%"
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAIMzpevLFRYEweTwss-poiy63HS9N65FE
              &q=2477+East+Greenwood+Rd,Prescott+Michigan">
          </iframe>
        </div>
        
      </div>
      
      <div className="surface-ground px-4 py-8 md:px-6 lg:px-8" id='contact-us'>
        <div className="grid">
          <div className="col-12 md:col-6 bg-no-repeat bg-right-bottom" style={{ backgroundImage: "url('assets/images/blocks/contact/contact-1.png')" }}>
            <div className="text-900 text-2xl font-medium mb-6">Contact Us</div>
            <div className="text-700 line-height-3 mb-6">Got a question or need assistance? We're here to help! Whether you have inquiries about bidding, item listings, payments, or any other aspect of our platform, we're committed to providing you with prompt and reliable assistance. Thank you for choosing 5 Dollar Auctions! </div>
            <ul className="list-none p-0 m-0 mt-6 text-700">
              <li className="flex align-items-center mb-3">
                <i className="pi pi-phone mr-2"></i>
                <a href='tel:9898731199'>+989-873-1199</a>
              </li>
              <li className="flex align-items-center mb-3">
                <i className="pi pi-facebook mr-2"></i>
                <span>@5dollarauctions</span>
              </li>
              <li className="flex align-items-center">
                <i className="pi pi-inbox mr-2"></i>
                <a href='mailto:info@5dollarauctions.com'>info@5dollarauctions.com</a>
              </li>
            </ul>
          </div>
              
          </div>
      </div>
      
      <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
          <div className="flex flex-wrap align-items-center justify-content-between mt-2">
                  <img src={logo} alt="5dollarauctions logo" className='w-2' />
              
              <div className="flex align-items-center justify-content-between md:mb-0 mb-3">
                  {/* <a className="text-700 cursor-pointer md:ml-5 ml-2">Brand Policy</a> */}
                  <a href='https://bid.5dollarauctions.com/privacy' className="text-700 cursor-pointer md:ml-5 ml-2">Privacy Policy</a>
                  <a href='https://bid.5dollarauctions.com/terms' className="text-700 cursor-pointer md:ml-5 ml-2">Terms of Service</a>
                  <a href='https://5dollarauctions.com/upload' className="text-700 cursor-pointer md:ml-5 ml-2">Admin Login</a>
              </div>
              <div className="flex align-items-center justify-content-between">
                  <a className="cursor-pointer text-500 md:ml-3 ml-2">
                      <i className="pi pi-instagram text-xl"></i>
                  </a>
                  <a className="cursor-pointer text-500 md:ml-3 ml-2">
                      <i className="pi pi-facebook text-xl"></i>
                  </a>
              </div>
          </div>
      </div>
    
      
      
    

    
    </div>
  );
}

export default Homepage;