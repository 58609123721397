import React, {useState, useEffect} from 'react'
import axios from 'axios';


import { PickList } from 'primereact/picklist';
import { Button } from 'primereact/button';

const palletTemplate = (item) => {
    return (
        <div className="flex flex-wrap align-items-center gap-3">
            {item}
        </div>
    );
};



const onChange = (event, setSource, setTarget) => {
    setSource(event.source);
    setTarget(event.target);
};


export default function AuctionAssemblypage() {
    const [pallets, setPallets] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedPallets, setSelectedPallets] = useState([]);
    const [multipleItems, setMultipleItems] = useState([]);
    const [dupes, setDupes] = useState([]);

    useEffect(() => {
        // /api/pallet/get
        axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/pallet/get') // gt all the pallets and put them in to the pallets list
            .then((res) => {
                setPallets(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        
            axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/getDupedLots') // get all duped lots and put them in to the multiples list
                .then((res) => {
                    setDupes(res.data);
                    setMultipleItems(res.data);
                    console.log(res.data)
                }).catch((err) => {
                    console.log(err);
                });
        }, [])



    useEffect(() => {
    if (selectedPallets.length < 1) { // If no pallets are selected, return
        return;
    }
    console.log('selectedPallets:', selectedPallets);
    axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/pallet/getLots', { palletNumber: selectedPallets }) // Get lots from selected pallets
        .then((res) => {
            console.log(res.data)
            

            
            setMultipleItems([...dupes, ...res.data]);
            

            // Now you can use setMultipleItems and setItems as needed
            // ...

        }).catch((err) => {
            console.log(err);
        });
        
    }, [selectedPallets])
        

    return (
        <div className='flex flex-column align-items-center'>
            <h1>Auction Assembly</h1>
            <PickList className='w-full' onChange={(e)=>onChange(e, setPallets, setSelectedPallets)} source={pallets} target={selectedPallets} itemTemplate={palletTemplate} sourceHeader="Available Pallet(s)" targetHeader="Selected Pallet(s)" />
            <div className='flex w-full justify-content-between gap-2'>
                <div className='flex flex-column w-6 gap-3'>
                    <div>
                        <h2>Items ({items.length})</h2>
                    </div>
                    {
                        items.map((item, index) => {
                            return (
                                <div key={index} className={item.flagged ? "flex p-2 align-items-center gap-3 border-solid border-1 border-red-200 border-round h-4rem" : "p-2 border-round surface-border border-1 flex h-4rem align-items-center gap-3"} onClick={(e)=>{
                                    //move this indexed element from multiple itesm to items
                                    let temp = items;
                                    temp.splice(index, 1);
                                    setItems(temp);
                                    setMultipleItems([...multipleItems, item]);
                                }}>
                                    <img src={item.images[0]} alt={item.title} className=" h-full" />
                                    <div className='text-sm'>
                                        <div className='text-sm'>
                                            {item.title}
                                        </div>
                                        <div className='text-xs'>
                                            <span>{item.lotNumber}</span>
                                            <span>{' | '+item.upc+(item.dupe ? " | ": '')}</span>

                                            {item.dupe ? <span className='text-red-500'>Dupe</span> : null}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='flex flex-column w-6 gap-3'>
                    <div>
                        <h2>Dupes/Items to Upload ({multipleItems.length})</h2>
                    </div>
                    {
                        multipleItems.map((item, index) => {
                            return (
                                <div key={index} className={item.flagged ? "flex p-2 align-items-center gap-3 border-solid border-1 border-red-200 border-round h-4rem" : "p-2 border-round surface-border border-1 flex h-4rem align-items-center gap-3"} onClick={(e)=>{
                                    //move this indexed element from multiple itesm to items
                                    let temp = multipleItems;
                                    temp.splice(index, 1);
                                    setMultipleItems(temp);
                                    setItems([...items, item]);
                                }}>
                                    <img src={item.images[0]} alt={item.title} className=" h-full" />
                                    <div className='text-sm'>
                                        <div className='text-sm'>
                                            {item.title}
                                        </div>
                                        <div className='text-xs'>
                                            <span>{item.lotNumber} | </span>
                                            <span className={item.consignor !== '796091' ? 'text-purple-500' : 'text-green-500'}>{item.consignor}</span>
                                            <span>{' | '+item.upc+(item.dupe ? " | ": '')}</span>

                                            {item.dupe ? <span className='text-red-500'>Dupe</span> : null}
                                            {/* quantity */}
                                            <span>{' | qty: '+item.quantity}</span>
                                            <span>{' | '+item.condition}</span>
                                            <span>{' | '+item.status}</span>
                                        </div>
                                        {/* missing arra */}
                                        <span className='text-xs'>{item.missing.length > 0 ? 'Missing: '+item.missing.join(', ') : null}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div>
                <Button onClick={()=>{
                    console.log('setMultipleItems:', multipleItems.map(item => item.lotNumber));
                    console.log('itemsList:', items.map(item => item.lotNumber));
                    axios.post('http://localhost:3001/api/auction/assemble', {lotList: items.map(item => item.lotNumber) , dupeList: multipleItems.map(item => item.lotNumber) }).then((res)=>{
                        let blob = new Blob([res.data]);
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = 'downloadedFile.zip';
                        a.target = '_blank';
                        a.click();
                        window.URL.revokeObjectURL(url);
                        console.log(url)
                    }).catch((err)=>{
                        console.log(err)
                    });
                }}>Submit</Button>
            </div>
        </div>
    )
}
