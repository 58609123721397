import React, { useContext, useState, useRef, useEffect } from 'react';
import useLib from '../Hooks/useLib';
import { Button } from 'primereact/button';
import { useNavigate, Link } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';
import { OverlayPanel } from 'primereact/overlaypanel';
import loginModal from '../Contexts/loginModal';
import Logo from '../Assets/Logo.png';
import { Divider } from 'primereact/divider';
import { set, update } from 'lodash';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function Nav({ style, bottomStyle }) {
  const navigate = useNavigate();
  const toggleLoginModal = useContext(loginModal);
  const wishList = useRef(null);
  const shoppingCart = useRef(null);
  const [cartList, setCartList] = useState([]);
  const [cart, setCart] = useState(null);
  const [favoritesList, setFavoritesList] = useState(null);
  const [favorites, setFavorites] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    let tempcart = JSON.parse(useLib.getCookie('5DACart'));
    setCartList(tempcart);
    let tempfavorites = JSON.parse(useLib.getCookie('5DAFavorites'));
    if (tempcart) {
      useLib.queryLot({ "lotNumber": { $in: tempcart } })
        .then(response => {
          setCart(response.data);
        })
        .catch(error => {
          // console.error(error);
        });
    }
    if (tempfavorites) {
      useLib.queryLot({ "lotNumber": { $in: tempfavorites } })
        .then(response => {
          setFavoritesList(response.data);
        })
        .catch(error => {
          // console.error(error);
        });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [shoppingCart]);

  return (
    <div className='flex flex-column z-2 bg-white' style={style}>
      <OverlayPanel ref={shoppingCart} className='lg:w-4 sm:w-6 w-full shadow-5 '>
      {
        favorites ?
          <div className='flex flex-column w-full'>
            <div className='text-2xl align-items-center w-full flex justify-content-between'>
              <span>Favorites</span>
              <Button label='My Cart' severity='info' icon='pi pi-shopping-cart' onClick={()=>setFavorites(!favorites)} />
            </div>
            <Divider />
            <div className='flex flex-column gap-3 max-h-25rem overflow-y-scroll scrollbarCustom'>
              {favoritesList &&
                favoritesList.map((lot, index) => (
                  <div className='flex align-items-center gap-2 h-4rem' key={index}>
                    <div className='h-full justify-content-center flex' style={{ aspectRatio: '4/3' }}>
                      <img alt={index} className='max-w-full max-h-full' src={lot.images[0]} />
                    </div>
                    {lot.title}
                  </div>
                ))}
            </div>
          </div>
        :
          <div className='flex flex-column w-full'>
            <div className='text-2xl align-items-center w-full flex justify-content-between'>
              <span>Shopping Cart</span>
              {/* add all cart.shop.prices for subtotal */}
              <span>Subtotal: ${cart && cart.length > 0 ? cart.reduce((a, b) => a + b.shop.price/100, 0).toFixed(2) : '0.00'}</span>
              {/* <Button label='Favorites' severity='info' icon='pi pi-star' onClick={()=>setFavorites(!favorites)} /> */}
            </div>
            <Divider />
            <div className='flex flex-column gap-3 max-h-25rem overflow-y-scroll'>
              {cart && cart.length > 0 ? 
                cart.map((lot, index) => (
                  <div className='flex align-items-center gap-2 h-4rem' key={index}>
                    {/* removelotfrom cart and removed this lot from the cart state and cartlist state */}
                    <Button className='border-none surface-none bg-none outline-none shadow-none w-1' icon='pi pi-times' size='small' outlined rounded  severity='danger' onClick={()=>{useLib.removeLotFromCart(lot.lotNumber); setCartList(JSON.parse(useLib.getCookie('5DACart'))); setCart(cart.filter((item)=>item.lotNumber !== lot.lotNumber))}} />
                    <div className='h-full justify-content-center flex' style={{ aspectRatio: '4/3' }}>
                      <img alt={index} className='max-w-full max-h-full' src={lot.images[0]} />
                    </div>
                    <div className='flex flex-column'>
                      <span className='text-700 text-sm'>{lot.title}</span>
                      <span className='text-700 text-align-end'>${(lot.shop.price/100).toFixed(2)}</span>
                    </div>
                  </div>
                ))
                :
                <div className='flex flex-column align-items-center'>
                  <div className='flex align-items-center justify-content-center gap-2 h-4rem'>
                    <span className='text-2xl'>Cart is empty</span>
                  </div>
                  <span>Select an item you'd like to purchase or save for later!</span>
                </div>
              }
            </div>
            
            {
              cart && cart.length > 0 &&
                <Button label='Checkout' severity='success' className='w-full mt-2' onClick={()=>{navigate('/checkout')}} />
            }
          </div>
      }
        
        
      </OverlayPanel>

      <div className='flex sm:flex-row flex-column w-full gap-4 p-3 sm:h-5rem align-items-center shadow-2 '>
        <img onClick={() => navigate('/')} src={Logo} alt='logo' className='sm:h-full h-6rem' />

        {windowDimensions.width > 1200 ? (
          <div className='flex justify-content-between w-full'>
            <div className='justify-content-between w-9'>
              <Link to={'https://bid.5dollarauctions.com'}>
                <Button icon='pi pi-comments' label='Auctions' text className='text-900' />
              </Link>
              {/* <Button icon='pi pi-money-bill' label='Sell' className='text-900' text onClick={() => navigate('/sell')} /> */}
              <Button icon='pi pi-phone' label='Contact' className='text-900' text onClick={() => window.location.href = '/#contact-us'} />
              {/* <Button icon='pi pi-info-circle' label='About' className='text-900' text onClick={() => navigate('/about-us')} /> */}
            </div>

            <div className='flex align-items-center h-3rem gap-4 px-4'>
              <Button
                icon='pi pi-shopping-bag'
                label='Buy It Now'
                className='text-900'
                style={{ marginRight: '-10px' }}
                text
                onClick={(event) => {
                  window.location.href = 'https://www.ebay.com/str/5dollarauctions';
                }}
              />

              {/* <Button size='medium' icon='pi pi-truck' label='Orders' text className='text-900' onClick={()=>window.location.href = '/checkorder'} /> */}
              <i
                className='pi pi-shopping-cart p-overlay-badge'
                style={{ fontSize: '1.4rem' }}
                onClick={(e) => {
                  shoppingCart.current.toggle(e);
                  if (useLib.getCookie('5DACart') != cartList) {
                    setCartList(JSON.parse(useLib.getCookie('5DACart')));
                    useLib.queryLot({ "lotNumber": { $in: JSON.parse(useLib.getCookie('5DACart')) } })
                      .then(response => {
                        setCart(response.data);
                      })
                      .catch(error => {
                        // console.error(error);
                      });
                  }
                }}
              >
                {useLib.getCookie('5DACart') && JSON.parse(useLib.getCookie('5DACart')).length > 0 && (
                  <Badge size={'small'} value={JSON.parse(useLib.getCookie('5DACart')).length} severity='success' />
                )}
              </i>
              
              
            </div>
          </div>
        ) : (
          
          <Button className='sm:w-auto w-full' icon='pi pi-user' label='Register Today!' severity='success' onClick={()=>window.location.href = 'https://bid.5dollarauctions.com/user/register'} />
        )}
      </div>

      {windowDimensions.width < 1200 && (
        <div className='justify-content-evenly flex sm:flex-row flex-column w-full border-bottom-3 border-200 ' style={bottomStyle}> 
          <Link to={'https://bid.5dollarauctions.com'}>
            <Button icon='pi pi-comments' label='Auctions' text className='text-700 sm:w-auto w-full' />
          </Link>
          
          <Button icon='pi pi-phone' label='Contact' className='text-700 noOutline border-noround' text onClick={() => window.location.href = '/#contact-us'} />
          <Button icon='pi pi-shopping-bag' label='Buy It Now!' className='text-700 noOutline border-noround' text onClick={() => window.location.href = 'https://www.ebay.com/str/5dollarauctions'} />
          <Button icon='pi pi-shopping-cart' label='Cart' className='text-700 noOutline border-noround' text onClick={(e) => {
                  shoppingCart.current.toggle(e);
                  if (useLib.getCookie('5DACart') != cartList) {
                    setCartList(JSON.parse(useLib.getCookie('5DACart')));
                    useLib.queryLot({ "lotNumber": { $in: JSON.parse(useLib.getCookie('5DACart')) } })
                      .then(response => {
                        setCart(response.data);
                      })
                      .catch(error => {
                        // console.error(error);
                      });
                  }
                }} />
          {/* <Button icon='pi pi-money-bill' label='Sell' className='text-700' text onClick={() => navigate('/sell')} /> */}
          {/* <Button icon='pi pi-info-circle' label='About' className='text-700' text onClick={() => navigate('/about-us')} /> */}
        </div>
      )}
    </div>
  );
}
