import React, {useState, useEffect} from 'react'
import useLib from '../../Hooks/useLib'
import axios from 'axios'


import { InputSwitch } from 'primereact/inputswitch';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
export default function Checkoutpage() {
    const [lots, setLots] = useState([])
    const [disabledLots, setDisabledLots] = useState([])
    const [orderTotal, setOrderTotal] = useState(0)

    const [localPickup, setLocalPickup] = useState(false)

    

    useEffect(() => {
        // get lots from the cart to display items in checkout
        let localLots = JSON.parse(useLib.getCookie('5DACart'))
        if (localLots) {
            localLots.forEach((lotNumber, i) => {
                axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/search', {
                    params: {
                        searchQuery: {lotNumber: lotNumber},
                        count: 1
                    }
                }).then(response => {
                    // if lot listingStatus is sold, or the listingPlatoform is not set to "store" remove it from the cart state and remove it from the cookie
                    if (response.data[0].listingStatus !== 'active' || response.data[0].listingPlatform !== 'store') {
                        localLots = localLots.filter(lot => lot !== lotNumber)
                        useLib.setCookie('5DACart', JSON.stringify(localLots), 1)
                        // if there are no lots in the cart, send user to shop
                        if (localLots.length === 0) {
                            window.location.href = '/shop'
                        }
                    }
                    // if lot has no shop.shipping price, move it to disabledLots else add it to lots
                    if (response.data[0].shop && response.data[0].shop.shipping) {
                        setLots(lots => [...lots, response.data[0]])
                    }else{
                        setDisabledLots(disabledLots => [...disabledLots, response.data[0]])
                    }
                }).catch(error => {
                    console.error(error)
                })
            });
            
            
        }
        
    }, [])


    useEffect(() => {
        // if any of the lots have no shop.shipping price, when the localPickup is set to false move them to disabledLots. if when the localPickup is set to true, move all disabledLots back to lots
        if (localPickup) {
            // move all disabledLots back to lots
            setLots(lots => [...lots, ...disabledLots])
            setDisabledLots([])
        }else{
            // move all lots with no shop.shipping price to disabledLots
            let tempDisabledLots = []
            lots.forEach(lot => {
                if (lot.shop && lot.shop.shipping) {
                    tempDisabledLots.push(lot)
                }else{
                    setDisabledLots(disabledLots => [...disabledLots, lot])
                }
            })
            setLots(tempDisabledLots)
        }
    }, [localPickup])

    useEffect(() => {
        //set order total
        let total = 0
        if (localPickup) {
            lots.forEach(lot => {
                total += lot.shop.price
            })
        }else{
            lots.forEach(lot => {
                total += lot.shop.price + lot.shop.shipping
            })
        }
        
        setOrderTotal(total)
        
    }, [lots])

    return (
        <div className='flex flex-column h-screen'>
            
                
            <div className='flex lg:flex-row-reverse flex-column h-full w-full'>
                <div className='w-full h-full flex flex-column gap-4 p-4 shadow-5 justify-content-between'>
                    <div className='flex flex-column gap-2'>

                    
                        <h1>Checkout Information</h1>
                        <div><span className='text-xl'>Pickup Locally?</span> (2477 E Greenwood Rd. Prescott, MI 48756)</div>
                        <div className='flex align-items-center gap-2'>
                            <InputSwitch checked={localPickup} severity='success' onChange={()=>setLocalPickup(!localPickup)} />
                            <div>{localPickup ? "Yes, I'll pick this up." : "No, Ship this to me."}</div>
                        </div>
                        <Accordion activeIndex={0}>
                            <AccordionTab header="Buyer Instructions">
                            {localPickup ?
                                <div>
                                    <div className='flex flex-column gap-2 p-2'>
                                        <div className='md:text-lg text-md '>
                                            1. Complete Payment
                                        </div>
                                        <span>
                                            Finish payment by clicking "Complete Payment" below.
                                        </span>
                                        
                                    </div>
                                    <div className='flex flex-column gap-2 p-2'>
                                        <div className='md:text-lg text-md '>
                                            2. Pickup Items
                                        </div>
                                        <span>
                                            Come pickup items within the allotted 30 days post sale. Bring a valid ID and or have payment verification E-mail readily available.
                                        </span>
                                    </div>
                                    
                                </div>
                                :
                                <div>
                                    <div className='flex flex-column gap-2 p-2'>
                                        <div className='md:text-lg text-md '>
                                            1. Complete Payment
                                        </div>
                                        <span className='md:text-md text-sm'>
                                            Finish payment by clicking "Complete Payment" below. You will be asked for shipping details then. Shipping will be added to your total, and the order will be shipped within 1-3 business days.
                                        </span>
                                        
                                    </div>
                                    <div className='flex flex-column gap-2 p-2'>
                                        <div className='md:text-lg text-md '>
                                            2. Receiving Items & Support
                                        </div>
                                        <span className='md:text-md text-sm '>
                                            If there are any issues with the items you recieve please contact us and we will work with you to resolve the issue.
                                        </span>
                                    </div>
                                </div>
                            }
                            </AccordionTab>
                            <AccordionTab header="Important Information">
                            <div className='lg:text-lg text-sm'>
                                {
                                    localPickup ?
                                    'You will be able to pick up your items at our location at 2477 E Greenwood Rd. Prescott, MI 48756. You will be expected to bring EITHER a valid ID that matches the name provided with the order OR the validation E-mail. We are available for pickups anytime we are open. You are given 30 days to pick up your items. If you do not pick up your items within 30 days, you will forfeit your items and they may be resold.'
                                    :
                                    <div>The shipping charge will be applied to your total at checkout. You will be asked to enter your shipping address at payment and you will be able to check on your order via the "Orders" button on the home page or you can contact us. If you are requesting a large order, please contact us at <a href='mailto:info@5dollarauctions.com'>info@5dollarauctions.com</a> and we may be able to consolidate your items into fewer shipments to save on shipping.</div>
                                    
                                }
                            </div>
                            </AccordionTab>
                        </Accordion>
                        
                        <div className='text-lg font-semibold'>To check on an order or get help, email us at <a href='mailto:info@5dollarauctions.com'>info@5dollarauctions.com</a> </div>
                    </div>
                    <div className='flex flex-column gap-2'>
                        
                        <div className='flex justify-content-between gap-2'>
                            {/* cancel button shold go to /shop */}
                            <Button label='Cancel Order' severity='danger' icon='pi pi-arrow-left' onClick={()=>window.location.href = '/shop'  }/>
                            <Button label='Complete Payment' severity='success' iconPos='right' icon='pi pi-arrow-right' onClick={()=>{
                                //send user to link created from stripe to send them to pay for the order
                                if (lots.length > 0) {
                                    axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/create-payment-link', {lotList: lots.map(lot => lot.lotNumber), localPickup: localPickup}).then(response => {
                                        window.location.href = response.data
                                    }).catch(error => {
                                        console.error(error)
                                    })
                                }else{
                                    window.location.href = '/shop'
                                }
                                
                            }} />
                        </div>
                        <span>By clicking the "Complete Payment" button above, I agree to the 5DollarAuctions.com's <a href='/terms'>Terms & Conditions</a></span>
                    </div>
                    
                </div>
                <div className='flex flex-column h-full w-full p-4'>
                        {/* total is shown next to order */}
                    <div className='flex justify-content-between'>
                        <h1>Order Total ${(orderTotal/100).toFixed(2)}</h1>
                        <h1>{lots.length} Items</h1>
                    </div>
                    <div className='flex flex-column h-full gap-2 lg:overflow-y-scroll overflow-y-visible w-full'>
                        {
                            lots && lots.map((lot, i) => {
                                return (
                                    <div key={i} className='flex sm:flex-row flex-column justify-content-start align-items-center sm:h-10rem h-20rem gap-2 pl-1'>
                                        {/* removed this lot from lots state */}
                                        <Button title='Remove' icon='pi pi-times' outlined rounded className='noOutline border-none h-1rem w-1rem hidden sm:flex' style={{aspectRatio: '1'}} severity='danger' onClick={()=>setLots(lots.filter(lot2 => lot2.lotNumber !== lot.lotNumber))} />
                                        <div className='overflow-hidden xl:max-w-10rem md:max-w-8rem max-w-6rem' style={{aspectRatio: '4/3'}}>
                                            <img src={lot.images[0]} className='h-full w-full' alt={lot.title}  style={{objectFit: 'contain'}} />
                                        </div>
                                        <div className='flex flex-column gap-2 w-full justify-content-between max-h-10rem py-5 pr-3'>
                                            
                                            <div className='md:text-lg sm:text-md text-sm'>{lot.title}</div>
                                            <div className='flex w-full justify-content-between'>
                                                <div className='md:text-md sm:text-sm text-xs'>{lot.lotNumber}</div>
                                                <div className='text-xl flex flex-column align-items-end'>
                                                    {/* I dont even know. This just checks if the shop object has a price key that holds the price. */}
                                                    {lot.shop && lot.shop.price !== undefined && lot.shop.price !== null ? `$${(lot.shop.price/100).toFixed(2)}` : 'N/A'} 
                                                    {
                                                        lot.shop && lot.shop.shipping ?
                                                            lot.shop.shipping > 0 && ( !localPickup ? <div className='text-sm'>+ ${(lot.shop.shipping/100).toFixed(2)} Shipping</div> : <div className='line-through text-sm'>+ ${(lot.shop.shipping/100).toFixed(2)} Shipping</div>)
                                                        :
                                                        <span className='text-sm text-red-500'>Pickup Only</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        disabledLots.length > 0 &&
                        <div className='flex flex-column gap-2'>
                            <div className='text-lg text-red-500'>These lots are only available for pickup. You must toggle this order to pickup to finialize purchase.</div>
                            {
                                disabledLots && disabledLots.map((lot, i) => {
                                    return (
                                    <div key={i} className='flex sm:flex-row flex-column  justify-content-start align-items-center h-10rem'>
                                        <div className='overflow-hidden xl:max-w-10rem md:max-w-8rem max-w-4rem' style={{aspectRatio: '4/3'}}>
                                            <img src={lot.images[0]} className='h-full w-full' alt={lot.title}  style={{objectFit: 'contain'}} />
                                        </div>
                                        <div className='flex flex-column gap-2 w-full justify-content-between h-full py-5 pr-3'>
                                            
                                            <div className='md:text-lg sm:text-md text-sm'>{lot.title}</div>
                                            <div className='flex w-full justify-content-between'>
                                                <div className='md:text-md sm:text-sm text-xs'>{lot.lotNumber}</div>
                                                <div className='text-xl flex flex-column align-items-end'>
                                                    {/* I dont even know. This just checks if the shop object has a price key that holds the price. */}
                                                    {lot.shop && lot.shop.price !== undefined && lot.shop.price !== null ? `$${(lot.shop.price/100).toFixed(2)}` : 'N/A'} 
                                                    {
                                                        lot.shop && lot.shop.shipping ?
                                                            lot.shop.shipping > 0 && ( !localPickup ? <div>+ ${(lot.shop.shipping/100).toFixed(2)}</div> : <div className='line-through'>+ ${(lot.shop.shipping/100).toFixed(2)}</div>)
                                                        :
                                                        <span className='text-sm text-red-500'>Pickup Only</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                                })
                            }
                        </div>
                    }
                </div>
                

            </div>
            
            {/* <div className='w-full h-1'>
                Complete Payment >
            </div> */}
            
            
            

        </div>
    )
}
