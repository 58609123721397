import React from 'react'
import Nav from '../../Components/Nav'
import { InputText } from 'primereact/inputtext'

export default function CheckOrderpage() {
    return (
        <div>
            <Nav />
            <div className='w-full flex'>
                <InputText placeholder='Order ID' />
            </div>
        </div>
    )
}
