import React, {useEffect, useState} from 'react'
import Nav from '../../Components/Nav'
import axios from 'axios'
import { InputText } from 'primereact/inputtext'
import { Divider } from 'primereact/divider'
import { Skeleton } from 'primereact/skeleton'
import { useNavigate } from 'react-router'
import { Button } from 'primereact/button/button.cjs'
import useLib from '../../Hooks/useLib'
export default function Shoppage() {
    //naviagte hook
    const Navigate = useNavigate()

    const [lots, setLots] = useState()
    const [noLots, setNoLots] = useState(false)
    const [updateState, setUpdateState] = useState(false)
    // cart from cookies transfered to state for faster loading
    const [stateCart, setStateCart] = useState([])
    const [stateFavorites, setStateFavorites] = useState([])


    //pagination 
    const [page, setPage] = useState(0)
    const [pageCount, setPageCount] = useState(24)

    useEffect(() => {
      if (JSON.parse(useLib.getCookie('5DACart'))) {
        setStateCart(JSON.parse(useLib.getCookie('5DACart')))
      }
      if (JSON.parse(useLib.getCookie('5DAFavorites'))) {
        setStateFavorites(JSON.parse(useLib.getCookie('5DAFavorites')))
      }
    }, [updateState])

    useEffect(() => {
      if (JSON.parse(useLib.getCookie('5DACart'))) {
        setStateCart(JSON.parse(useLib.getCookie('5DACart')))
      }
      if (JSON.parse(useLib.getCookie('5DAFavorites'))) {
        setStateFavorites(JSON.parse(useLib.getCookie('5DAFavorites')))
      }

      axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/search', {
          params: {
              search: '',
              //surplus field exists on lot 
              skip: page * pageCount,
              searchQuery: {shop: {$exists: true}, listingStatus: 'active', listingPlatform: 'store'}, 
              count: pageCount
          }
      }).then(response => {
        if (response.data.length > 0) {
          
          setLots(response.data)
        }else{
          setNoLots(true)
        }
      }).catch(error => {
          console.error(error)
      })
    }, [page, pageCount])

    return (
        <div className='flex flex-column w-full surface-ground'>
            <Nav />
            <div className='p-4 flex flex-column gap-2 w-full'> 
                <div className='flex md:flex-row flex-column gap-3 w-full justify-content-between align-items-center'>
                  <div className='text-3xl font-semibold'>Browse Buy It Now</div>
                </div>
                
                {
                  lots ?
                    <div className='grid'>
                        {
                          lots.map((lot, index) => {
                            return (
                              <div className='lg:col-3 sm:col-6 xs:col-12 mt-2' >
                                <div className='w-full border-round p-3 shadow-2 surface-0 flex flex-column gap-2'>
                                  <div className='w-full border-round' style={{aspectRatio: '4/3', display: 'flex', justifyContent: 'center', overflow: 'hidden' }} onClick={()=>Navigate('/lot/'+lot.lotNumber)}>
                                      <img alt={index} className='max-w-full max-h-full object-cover' style={{ objectFit: 'cover' }} src={lot.images[0]} />
                                  </div>
                                  <div className='p-flex p-flex-column' style={{height: '4rem'}} onClick={()=>Navigate('/lot/'+lot.lotNumber)}>
                                      <span className='p-text-overflow-ellipsis p-text-truncate' style={{ display: 'block', overflow: 'hidden', maxHeight: '2.9em', WebkitBoxOrient: 'vertical' }}>
                                          {lot.title}
                                      </span>
                                      <span className='text-700 text-sm'>{lot.lotNumber}</span>
                                  </div>
                                  <div className='flex justify-content-between align-items-center'>
                                    <div className='text-xl w-8 flex flex-column'>
                                      {/* I dont even know. This just checks if the shop object has a price key that holds the price. */}
                                      {lot.shop && lot.shop.price !== undefined && lot.shop.price !== null ? `$${(lot.shop.price/100).toFixed(2)}` : 'N/A'} 
                                      {
                                        lot.shop && lot.shop.shipping ?
                                            lot.shop.shipping > 0 ?
                                                <span className='text-sm'>+ ${(lot.shop.shipping/100).toFixed(2)} Shipping <span className='text-800 text-xs'>(Free with Local Pickup)</span></span>
                                            :
                                                <span className='text-sm text-green-600'>Free Shipping</span>
                                        :
                                        <span className='text-sm'>Pickup Only</span>
                                      }
                                    </div>
                                    <div className='flex gap-2 w-4 justify-content-end'>
                                      {/* whishlist and add to cart button */}
                                      {/* {
                                        stateFavorites.includes(lot.lotNumber) ?
                                          <i className={'pi pi-heart-fill text-white p-2 border-circle bg-red-500'} style={{ fontSize: '1.2rem' }} onClick={()=>{useLib.removeLotFromFavorites(lot.lotNumber); setUpdateState(!updateState)}} />
                                        :
                                          <i className='pi pi-heart p-2' style={{ fontSize: '1.2rem' }} onClick={()=>{useLib.addLotToFavorites(lot.lotNumber); setUpdateState(!updateState)}} />
                                      } */}
                                      {
                                        stateCart.includes(lot.lotNumber) ?
                                          <i className='pi pi-shopping-cart text-white p-2 border-circle bg-green-500' style={{ fontSize: '1.2rem' }} onClick={()=>{useLib.removeLotFromCart(lot.lotNumber); setUpdateState(!updateState)}} />
                                        :
                                          <i className='pi pi-cart-plus p-2' style={{ fontSize: '1.2rem' }} onClick={()=>{useLib.addLotToCart(lot.lotNumber); setUpdateState(!updateState)}} />
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                    </div>
                  :
                    noLots ?
                      <div className='flex justify-content-center'>
                        <h1>No Lots Available</h1>
                      </div>
                    :
                    <Skeleton height='100px' className='w-full' />
                }

            </div>

            <div className='flex w-full justify-content-center gap-2 p-4'>
              {
                page > 0 &&
                <Button label='Last Page' onClick={()=>{setPage(page-1)}} icon='pi pi-arrow-left' />
              }
              {
                lots && lots.length === pageCount &&
                <Button label='Next Page' onClick={()=>setPage(page+1)} icon='pi pi-arrow-right' iconPos='right' />
              }
            </div>
            

        </div>
    )
}
