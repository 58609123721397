import React, {useState, useEffect,  useContext} from 'react'
import Uploadpage from './Pages/Uploadpage/Uploadpage.js';
import AuctionAssemblypage from './Pages/AuctionAssemblypage/AuctionAssemblypage.js';
import Locationpage from './Pages/Locationlist/Locationlist';
// import Lotpage from './Pages/Lotpage/Lotpage.js';
import Shippingpage from './Pages/Shippingpage/Shippingpage';
import Shoppage from './Pages/Shoppage/Shoppage.js';
import Fullfillmentpage from './Pages/Fullfillmentpage/Fullfillmentpage.js';
import Homepage from './Pages/Homepage/Homepage.js';
import CheckOrderpage from './Pages/CheckOrderpage/CheckOrderpage.js';
import ViewLotpage from './Pages/ViewLotpage/ViewLotpage.js';
import Importpage from './Pages/Importpage/Importpage.js';
import Termspage from './Pages/Termspage/Termspage.js';
import Sellpage from './Pages/Sellpage/Sellpage.js';
import CheckoutSuccesspage from './Pages/CheckoutSuccesspage/CheckoutSuccesspage.js';
import Checkoutpage from './Pages/Checkoutpage/Checkoutpage.js';
import Login from './Components/Login.js';
import Nav from './Components/Nav.js';

import { Dialog } from 'primereact/dialog';

import loginModal from './Contexts/loginModal.js';

import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom';


export default function App() {
  const [open, setOpen] = useState(false);

  function toggleLoginModal() {
    setOpen(!open);
  }

  useEffect(() => {
    // if theres a ?lot query, redirect to the viewlot page
    if (window.location.search.includes('?lot=')) {
      window.location.href = '/viewlot/' + window.location.search.split('?lot=')[1];
    }
  }, []);
  

  return (
    <div>
      <loginModal.Provider value={toggleLoginModal}>
        <Dialog visible={open} onHide={()=>toggleLoginModal()} className='lg:w-6 md:w-8 '>
          <Login />
        </Dialog>
        <Router >
          
          <Routes>
              <Route path='/shipping/:length?/:width?/:height?/:weight?/:tzip?' element={<Shippingpage/>} />
              <Route path='/locationList' element={<Locationpage/>} />
              {/* <Route path='/lot/:lotNumber' element={<Lotpage/>} /> */}
              {/* <Route path='/lotlist' element={<LotListingpage/>} /> */}
              <Route path='/sell' element={<Sellpage/>} />
              <Route path='/upload' element={<Uploadpage/>} />
              <Route path='/checkout' element={<Checkoutpage/>} />
              <Route path='/checkoutsuccess' element={<CheckoutSuccesspage/>} />
              <Route path='/import' element={<Importpage/>} />
              <Route path='/viewlot/:lot' element={<ViewLotpage/>} />
              <Route path='/fullfillment' element={<Fullfillmentpage/>} />
              <Route path='/checkorder' element={<CheckOrderpage/>} />
              <Route path='/terms' element={<Termspage/>} />
              <Route path='/shop' element={<Shoppage/>} />

              <Route path='/auctionassembly' element={<AuctionAssemblypage/>} />
              <Route path='/' element={<Homepage/>} />
          </Routes>

        </Router>
      </loginModal.Provider>
    </div>
  )
}
