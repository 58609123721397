import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';

const CameraCapture = React.forwardRef(({ width, height, className }, ref) => {
  const videoRef = useRef();
  const streamRef = useRef();
  const canvasRef = useRef();
  const [videoReady, setVideoReady] = useState(false);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment', // Rear camera
        },
      });

      videoRef.current.srcObject = stream;
      streamRef.current = stream;
    } catch (error) {
      console.error('Error accessing camera stream:', error);
    }
  };

  const captureFrame = () => {
    if (videoReady) {
      const { current: video } = videoRef;

      if (video) {
        const { current: canvas } = canvasRef;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas image to a base64 string
        const base64Image = canvas.toDataURL('image/jpeg', 1.0);
        return base64Image;
      }
    }

    return null;
  };

  useEffect(() => {
    startCamera();
    videoRef.current.onloadeddata = () => {
      setVideoReady(true);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    captureFrame: captureFrame,
  }));

  return (
    <div className="w-full">
      <video ref={videoRef} autoPlay playsInline muted className="w-full"></video>
      <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
    </div>
  );
});

export default CameraCapture;














