import React, {useState, useEffect} from 'react'
import { useParams, useLocation, useNavigation, useNavigate } from 'react-router-dom';

import logo from '../../Assets/Logo.webp'
import bigLinc from '../../Assets/bigLinc.jpg'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { ProgressBar } from 'primereact/progressbar';

import axios, { all } from 'axios';
import useLib from '../../Hooks/useLib.js';

function getAllQueryParams(locationSearch) {
    const queryParams = new URLSearchParams(locationSearch);
    const paramsObj = {};
  
    // Check if there are any query parameters at all
    if (!locationSearch) {
      return paramsObj;
    }
  
    for (let [key, value] of queryParams.entries()) {
      paramsObj[key] = value;
    }
  
    return paramsObj;
  }


  
export default function Shippingpage() {
    const [finalLength, setFinalLength] = useState(0);
    const [finalWidth, setFinalWidth] = useState(0);
    const [finalHeight, setFinalHeight] = useState(0);
    const [finalWeight, setFinalWeight] = useState(0);
    const [itemImage, setItemImage] = useState(bigLinc);
    const [zip, setZip] = useState('');
    const [lotNumber, setLotNumber] = useState('');
    const [lotTitle, setLotTitle] = useState('');
    const [lotCost, setLotCost] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [iframe, setIframe] = useState(false);
    const [ineligable, setIneligable] = useState(false); // if true, show error message and disable button
    const location = useLocation();
    const allParams = getAllQueryParams(location.search);
    const navigate = useNavigate();
    
    let { length, width, height, weight, tzip } = useParams();

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      };

    function getShippingEstimate () {
        if (zip.length != 5 || parseFloat(zip) < 1 || parseFloat(zip) > 99950 ) { // zip codes range from 00001 to 99950
            alert('Please enter a valid zip code');
            setZip('');
        }else{
            axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/getShippingEstimate', {
                params: {
                    lotNumber: lotNumber,
                    zip: zip
                }}
            ).then((res) => {
                setLotCost(res.data.price);
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsLoading(false);
            })
        }
        document.cookie = "5DAzip="+zip;
    }

    useEffect(() => {
        if (getCookie('5DAzip')) {
            setZip(getCookie('5DAzip'));
        }
        if (allParams.iframe && allParams.iframe == 'true') {
            setIframe(true);
        }
        if (allParams.lotNumber || allParams.lot) {
            if (useLib.parseLot('lot-'+allParams.lotNumber)) {
                setLotNumber(allParams.lotNumber || allParams.lot);
                axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/search', {
                    params: {
                        search: allParams.lotNumber || allParams.lot
                    }}
                ).then((res) => {
                    if (res.data[0]) {
                        setLotTitle(res.data[0].title);
                        setItemImage(res.data[0].images[0]);
                        
                        if (!res.data[0].height || !res.data[0].width || !res.data[0].length || !res.data[0].weight) { //check if item is eligable for shipping
                            setIneligable(true);
                        }else{
                            setFinalHeight(res.data[0].height);
                            setFinalLength(res.data[0].length);
                            setFinalWeight(res.data[0].weight);
                            setFinalWidth(res.data[0].width);
                        }
                    }else{
                        setIneligable(true);
                    }
                    
                    
                }).catch((err) => {
                    console.log(err);
                })
                // if (!length || !width || !height || !weight) {
                //     if ((!allParams.length && !allParams.l) || (!allParams.width && !allParams.w) || (!allParams.height && !allParams.h) || (!allParams.weight && !allParams.wt)) {
                //         setErrorPage(true);
                //     }else{
                //         setFinalLength(allParams.length || allParams.l);
                //         setFinalWidth(allParams.width || allParams.w);
                //         setFinalHeight(allParams.height || allParams.h);
                //         setFinalWeight(allParams.weight || allParams.wt);
                //         setZip(allParams.zip || allParams.z);
                //     }
                    
                // }else{
                //     setFinalLength(length);
                //     setFinalWidth(width);
                //     setFinalHeight(height);
                //     setFinalWeight(weight);
                //     setZip(tzip);
                // }
            }else{
                setErrorPage(true);
            }
        }else{
            setErrorPage(true);
        }
    }, [])


    return (
        <>
            {iframe ?
                <div className='flex flex-column align-items-center p-2 overflow-hidden'>
                    {
                        !errorPage &&
                        <>
                            <h1 className='sm:text-auto text-lg'>Shipping Estimate Calculator</h1>
                            <div className='flex flex-column sm:flex-row p-2 gap-2 w-screen sm:w-auto'>
                                <div className='flex flex-row flex-wrap'>
                                {
                                    finalHeight && finalLength && finalWeight && finalWidth ?
                                        <div className='flex flex-column justify-content-evenly'>
                                            <span className='text-md sm:text-xl'>Item Dimensions: {finalLength+'" x '+finalWidth+'" x '+finalHeight}"</span>
                                            <span className='text-md sm:text-xl'>Item Weight: {finalWeight} lbs</span>
                                        </div>
                                    :
                                        ineligable ? 
                                            <></>
                                        :
                                            <Skeleton width='100%' height='3rem' className='p-skeleton-text justify-content-center flex align-items-center text-md'>Item Info Loading...</Skeleton>
                                }
                                </div>
                                {
                                    !ineligable ?
                                        <>
                                            {
                                            isLoading ?
                                                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                            :
                                                lotCost ?
                                                    <div className='flex flex-column gap-2 border-round border-green-400 border-2 p-2 align-items-center '>
                                                        <span className='text-md sm:text-xl'>Shipping Cost</span>
                                                        <span className='text-4xl sm:text-5xl'>${lotCost.toFixed(2)}</span>
                                                        <div className='flex w-full justify-content-center sm:text-sm text-xs text-700 text-center'>
                                                            It is important to note that this is only an estimate and the actual cost may vary. <br />
                                                        </div>
                                                        
                                                    </div>
                                                :
                                                    <Skeleton width='100%' height='3rem' className='p-skeleton-text justify-content-center flex align-items-center text-md'>Enter Your Zip Below...</Skeleton>
                                                    
                                            }
                                            <div className='flex'>
                                                <span className='flex p-inputgroup border-noround-right'>
                                                    <InputText placeholder='Zip Code' maxLength={5} keyfilter="int" value={zip} className='w-full' onChange={(e)=>setZip(e.target.value)} />
                                                    <Button className='border-round-right pr-6 md:text-sm text-xs' icon='pi pi-calculator' severity='success' label='Calculate' onClick={()=>{getShippingEstimate(); setIsLoading(true)}} />
                                                </span>
                                                
                                            </div>  
                                        </>
                                    :
                                        <div className='flex flex-column align-items-center gap-2'>
                                            <span className='text-md sm:text-xl'>This item is not eligable for shipping.</span>
                                            <span className='text-md sm:text-xl'>Please contact us for more information.</span>
                                        </div>
                                }
                                
                                
                            </div>
                        </>
                    }
                </div>
            :
                <div className='flex flex-column align-items-center p-2'>
                    <img src={logo} alt='Logo' className=' w-9 sm:w-4 mt-4' />
                    {
                        errorPage ? 
                            <div className='flex flex-column align-items-center gap-2 px-2 pt-8 h-screen'>
                                <strong className='text-md sm:text-xl'>There was an error with your request. Please try again.</strong>
                                <p className='text-sm sm:text-lg'>
                                    This item may not have been indexed correctly, feel free to contact us at <a href='mailto:info@5dollarauctions.com'>info@5dollarauctions.com</a>
                                </p>
                                <Button className='w-full flex gap-2' severity='danger' icon='pi pi-arrow-left' onClick={() => window.history.back()}>Go Back</Button>
                            </div>
                        :
                        <>
                            <h1 className='sm:text-auto text-lg'>Shipping Estimate Calculator</h1>
                            <div className='flex flex-column p-2 gap-2 w-screen sm:w-auto'>
                                { lotNumber && !ineligable &&
                                    <div className='flex w-full p-2 gap-2 border-solid border-300 border-1 border-round'>
                                        <img src={itemImage} alt='Item Image' className='w-2 sm:w-24' />
                                        <div className='flex flex-column overflow-hidden '>
                                            <span className='text-md sm:text-xl'>{lotNumber}</span>
                                            <span className='text-xs sm:text-sm'>{lotTitle}</span>
                                        </div> 
                                    </div>
                                }
                                {
                                    finalHeight && finalLength && finalWeight && finalWidth ?
                                        <>
                                            <span className='text-md sm:text-xl'>Item Dimensions: {finalLength+'" x '+finalWidth+'" x '+finalHeight}"</span>
                                            <span className='text-md sm:text-xl'>Item Weight: {finalWeight} lbs</span>
                                        </>
                                    :
                                        ineligable ? 
                                            <></>
                                        :
                                            <Skeleton width='100%' height='3rem' className='p-skeleton-text justify-content-center flex align-items-center text-md'>Item Info Loading...</Skeleton>
                                }
                                {
                                    !ineligable ?
                                        <>
                                            {
                                            isLoading ?
                                                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                                            :
                                                lotCost ?
                                                    <div className='flex flex-column gap-2 border-round border-green-400 border-2 p-2 align-items-center '>
                                                        <span className='text-md sm:text-xl'>Shipping Cost</span>
                                                        <span className='text-4xl sm:text-5xl'>${lotCost.toFixed(2)}</span>
                                                        <div className='flex w-full justify-content-center sm:text-sm text-xs text-700 text-center'>
                                                            It is important to note that this is only an estimate and the actual cost may vary. <br />
                                                        </div>
                                                        
                                                    </div>
                                                :
                                                    <Skeleton width='100%' height='3rem' className='p-skeleton-text justify-content-center flex align-items-center text-md'>Enter Your Zip Below...</Skeleton>
                                                    
                                            }
                                            <div className='flex'>
                                                <span className='flex p-inputgroup border-noround-right'>
                                                    <InputText placeholder='Zip Code' maxLength={5} keyfilter="int" value={zip} className='w-full' onChange={(e)=>setZip(e.target.value)} />
                                                    <Button className='border-round-right pr-6' icon='pi pi-calculator' severity='success' label='Calculate' onClick={()=>{getShippingEstimate(); setIsLoading(true)}} />
                                                </span>
                                                
                                            </div>  
                                        </>
                                    :
                                        <div className='flex flex-column align-items-center gap-2'>
                                            <span className='text-md sm:text-xl'>This item is not eligable for shipping.</span>
                                            <span className='text-md sm:text-xl'>Please contact us for more information.</span>
                                        </div>
                                }
                                
                                
                                <div className='relative w-full bottom-0 left-0'>
                                    <Button className='w-full flex gap-2' severity='danger' icon='pi pi-arrow-left' onClick={() => navigate(-1)}>Go Back</Button>
                                </div>
                                
                                <div>
                                    <h2 className='md:text-md text-lg text-center'>
                                        Things to keep in mind
                                    </h2>
                                    <ul className='list-disc md:text-md text-sm'>
                                        <li>
                                            You can save money on shipping by purchasing multiple items per auction. We combine items to make shipments cheaper for you.
                                        </li>
                                        <li>
                                            Shipping estimates are NOT final and will very likely vary from the given estimate.
                                        </li>
                                        <li>
                                            This shipping estimator only gives the rate for 1 item at time. If you are purchasing multiple items, please contact us for a more accurate estimate.
                                        </li>
                                        <li>
                                            There are items that cannot be shipped. If you purchase multiple items and one of them cannot be shipped, you will still be required to pick that item up.
                                        </li>
                                    </ul>
                                </div>
                                
                                
                            </div>
                            <p className='text-center text-sm'>
                                If you're having trouble or need help, feel free to contact us at <a href='mailto:info@5dollarauctions.com'>info@5dollarauctions.com</a>
                            </p>
                        </>
                    }
                </div>
            }
        </>
    )
}
