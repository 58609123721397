import React, {useContext, useState, useRef} from 'react'
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import Logo from '../Assets/Logo.png';

export default function Login({open}) {
    const [checked1, setChecked1] = useState(false);

    return (
        <>
            <div className="text-center mb-5">
                <img src={Logo} alt="Logo" className="mb-3 lg:h-6rem h-4rem" />
                <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                
                <div>
                    <div>
                        <span className="text-600 font-medium line-height-3">Don't have an account?</span>
                        <a className="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a>
                    </div>
                    <span className="text-600 font-small line-height-3">This login is for the shop only! There is a <a href='https://bid.5dollarauctions.com/user/login'>seperate login</a> for the auctions.</span>
                </div>
                
            </div>

            <form onSubmit={(e)=>{e.preventDefault();}}>
                <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                <InputText id="email" type="text" placeholder="Email address" className="w-full mb-3" />

                <label htmlFor="password" className="block text-900 font-medium mb-2">Password</label>
                <InputText type="password" placeholder="Password" className="w-full mb-3" />

                <div className="flex align-items-center justify-content-between mb-6">
                    <div className="flex align-items-center">
                        <Checkbox id="rememberme" className="mr-2" checked={checked1} onChange={(e) => setChecked1(e.checked)} />
                        <label htmlFor="rememberme">Remember me</label>
                    </div>
                    <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot your password?</a>
                </div>

                <Button label="Log-in" icon="pi pi-user" type='submit' className="w-full" />
            </form>   
        </>
    ) 
}
