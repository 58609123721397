import React from 'react'
import {Button} from 'primereact/button'

export default function CheckoutSuccesspage() {
    return (
        <div className='flex flex-column w-screen h-screen align-items-center justify-content-center gap-2 p-4'>
            <i className="pi pi-check-circle text-green-500" style={{'fontSize': '5rem'}}></i>
            <h1 className='text-3xl text-center'>Thank you for your purchase!</h1>
            <h2 className='text-2xl text-center'>Your order has been placed.</h2>
            <div className='flex flex-column gap-3'>
                <span>
                    You will recieve a receipt via E-mail shortly. If you have any questions, please contact us at <a href='mailto:info@5dollarauctions.com'>info@5dollarauctions.com</a>
                </span>
                <span>
                    If your order is being shipped, you will recieve a tracking number via E-mail once your order has been shipped.
                </span>
            </div>
            

            <div className='flex flex-row gap-2 mt-4'>
                <Button label='Return Home' className='p-button-raised' onClick={()=>window.location.href='/'} />
                <Button severity='success' label='Continue Shopping' className='p-button-raised' onClick={()=>window.location.href='/shop'} />
            </div>

        </div>
    )
}
