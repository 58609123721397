import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';

export default function ViewLotpage() {
    const { lot } = useParams();
    const [lotData, setLotData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/get?lotNumber=' + lot)
            .then((res) => {
                setLotData(res.data);
            })
            
            .catch((err) => {
                alert('Lot not found');
                window.location.href = '/';
            });
    }, [lot]);

    return (
        <div className='flex flex-column h-screen'>
            <div className='w-full gap-4 lg:p-5 md:p-4 sm:p-0 flex flex-column justify-content-center'>
                <div className='gap-3 p-2 flex align-items-center'>
                    <Button icon="pi pi-arrow-left" className='text-900 shadow-2 surface-300 border-none w-2rem' style={{ borderRadius: '100%', aspectRatio: '1' }} onClick={() => { navigate('/') }} />
                    <div className='font-bold'>Back</div>
                </div>
                <div className='flex lg:flex-row flex-column gap-4 w-full justify-content-center'>
                    <div className='lg:w-5 w-full overflow-hidden md:border-round shadow-2'>
                        {
                            lotData.images ?
                                <ImageGallery items={lotData.images.map((img) => { return { original: img, thumbnail: img } })}
                                    showPlayButton={false}
                                    showBullets={false}
                                    thumbnailPosition={'bottom'}
                                    showIndex={true}
                                />
                                :
                                <Skeleton className='w-full h-full' />
                        }
                    </div>
                    <div className='flex flex-column gap-2 lg:w-7 lg:px-0 px-2 w-full'>
                        <div className='flex flex-column gap-1 px-2 md:px-0'>
                            <div className='xlg:text-2xl text-lg'>{lotData.title}</div>
                            <div className='text-md'>{lotData.lotNumber}</div>
                            {/* location */}
                            <div className='text-md'>{lotData.locationNumber ? lotData.locationNumber : <Skeleton className='w-full' height='3rem' />}</div>
                            <div className='flex flex-column gap-2 py-2'>
                                <div className='text-2xl'>Condition</div>
                                <div className='text-md'>
                                    {lotData.condition ? lotData.condition : <Skeleton className='w-full' height='3rem' />}
                                </div>
                                {
                                    lotData.missing && lotData.missing.length > 0 && (
                                        <div>
                                            <div className='text-2xl'>Missing</div>
                                            <div className='text-md'>
                                                {
                                                    lotData.missing.map((item, index) => (
                                                        <div key={index}>{item}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div>
                                <div className='text-2xl'>Description</div>
                                <div className='text-md'>
                                    {lotData.description ? lotData.description : <Skeleton className='w-full' height='3rem' />}
                                </div>
                            </div>

                        </div>
                        <Button label="Our Past Auctions" onClick={() => { window.location.href = 'https://bid.5dollarauctions.com/auctions#YXVjdGlvbltzdGF0dXNdPXBhc3Q.' }} severity='success' className='w-full p-button-raised rounded' />
                        <Button label="Our Online Store" onClick={() => { window.location.href = 'https://www.ebay.com/str/5dollarauctions' }} className='w-full p-button-raised rounded' />
                    </div>
                </div>
            </div>
        </div>
    );
}
