import React, { useState, useEffect, useRef, useCallback} from 'react'


import useLib from '../../Hooks/useLib.js';
import './Uploadpage.css'

import Webcam  from "react-webcam";

import { Button } from 'primereact/button';
// import io from 'socket.io-client';
import Moment from 'react-moment';
import error from '../../Assets/error.mp3';
import success from '../../Assets/success.mp3';
import sent from '../../Assets/sent.mp3';
import trash from '../../Assets/trash.mp3';
import alertSound from '../../Assets/alert.mp3';
import shutter from '../../Assets/shutter.mp3';

// import { Divider } from 'primereact/divider';
// import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { FileUploader } from "react-drag-drop-files";
import { ToggleButton } from 'primereact/togglebutton';
import { Paginator } from 'primereact/paginator';
import { Accordion, AccordionTab } from 'primereact/accordion';
        
// import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { InputTextarea } from 'primereact/inputtextarea';
import {ProgressSpinner} from 'primereact/progressspinner';
import { Messages } from 'primereact/messages';
// import { Skeleton } from 'primereact/skeleton';
// import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ListBox } from 'primereact/listbox';
import axios from 'axios';
import logo from '../../Assets/Logo.webp';
import { InputText } from 'primereact/inputtext';
import { upload } from '@testing-library/user-event/dist/upload.js';
import { Label, TextInput } from 'prime-react';
import CameraCapture from '../../Components/Cameracapture.js';
// import Camera from 'react-html5-camera-photo';
// import { FACING_MODES } from 'react-html5-camera-photo';
// import FasttrackMenu from '../../Components/FasttrackMenu/FasttrackMenu';
// import 'react-html5-camera-photo/build/css/index.css';
// import './Fasttrackpage.css'
// import success from "../../Assets/success.mp3";
// import error from "../../Assets/fail.mp3";
// import complete from "../../Assets/complete.mp3";
// import Logo from '../../Assets/fasttracklogo.png'


// PAGE REFERENCE
// 0 = Login
// 1 = Menu
// 2 = Upload
// 3 = Locate
// 4 = Orders
// 5 = View/Edit

// const socket = io('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com');

export default function Uploadpage() {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
    const { parsePallet, parseLot, selectInput, openFullscreen, AdjustImages } = useLib;

    const [startY, setStartY] = useState(null); //used for drag and drop
    const [startX, setStartX] = useState(null); //used for drag and drop
    const [update, setUpdate] = useState(false); //used to update the page
    //STATES
    const [page, setPage] = useState(0)
    const [atWarehouse, setAtWarehouse] = useState(true)

    const msgs = useRef(null); //toast for errors and success

    const [code, setCode] = useState('') //code used to login
    const [username, setUsername] = useState('') //username of the user after login

    //Upload Page States
    const [uploadStep, setUploadStep] = useState(0) //step of the upload process
    const [pallet, setPallet] = useState(null) //pallet number
    const [uploadsToday, setUploadsToday] = useState(0) //number of uploads today
    const [lotsLocatedToday, setLotsLocatedToday] = useState(0) //number of lots located today

    const camera = useRef(null) //camera ref

    const [uploadLotNumber, setUploadLotNumber] = useState('')
    const [uploadConsignor, setUploadConsignor] = useState(null) // gets set from the useLot hook
    const [uploadImages, setUploadImages] = useState([]) //array of images to upload

    const [uploadConditions, setUploadConditions] = useState([
        {label: 'New', value: 'New'},
        {label: 'New Cosmetic Damage', value: 'New Cosmetic Damaged'},
        {label: 'Open Box Cosmetic Damaged', value: 'Open Box Cosmetic Damaged'},
        {label: 'New Open Box', value: 'New Open Box'},
        {label: 'New Old Stock', value: 'New Old Stock'},
        {label: 'Used', value: 'Used'},
        {label: 'Slightly Used', value: 'Slightly Used'},
        {label: 'Parts & Repair', value: 'Parts & Repair'}
    ]) //array of conditions to upload
    const [uploadCondition, setUploadCondition] = useState('') //condition of the item
    const [uploadCustomCondition, setUploadCustomCondition] = useState('') //custom condition of the item
    
    const [uploadMissings, setUploadMissings] = useState([
        {label: 'Hardware', value: 'Hardware'},
        {label: 'Manual', value: 'Manual'},
        {label: 'Cable(s)', value: 'Cable(s)'},
        {label: 'Attachment(s)', value: 'Attachment(s)'},
        {label: 'Quantity', value: 'Quantity'},
        {label: 'Bag', value: 'Bag'}
    ]) //array of missing item options
    const [uploadMissing, setUploadMissing] = useState([]) //missing items
    const [uploadCustomMissing, setUploadCustomMissing] = useState('') //custom missing item
    
    const [uploadStatuses, setUploadStatuses] = useState([
        {label: 'Tested Works', value: 'Tested Works'},
        {label: 'Tested Works w/ Cosmetic Damage', value: 'Tested Works w/ Cosmetic Damage'},
        {label: 'Unable to Test', value: 'Unable to Test'}
    ]) //array of statuses to upload
    const [uploadStatus, setUploadStatus] = useState('') //status of the item
    const [uploadCustomStatus, setUploadCustomStatus] = useState('') //custom status of the item
    const [uploadUpc, setUploadUpc] = useState('') //upc of the item
    const [uploadSearch, setUploadSearch] = useState('') //search for the upc of the item
    const [uploadSearchResults, setUploadSearchResults] = useState([]) //search results for the upc of the item
    const [uploadUpcResults, setUploadUpcResults] = useState([]) //upc results of the item
    const [uploadMenu, setUploadMenu] = useState(false) //menu of the item
    const [uploadConfirmMenu, setUploadConfirmMenu] = useState(false) //confirm menu of the item
    const [uploadConfirmAction, setUploadConfirmAction] = useState('') //confirm action of the item
    
    const [uploadQuantity, setUploadQuantity] = useState(1) //quantity of the item
    const [uploadTitle, setUploadTitle] = useState('') //title of the item
    const [uploadDescription, setUploadDescription] = useState('') //description of the item
    const [uploadColor, setUploadColor] = useState('') //color of the item
    const [uploadModel, setUploadModel] = useState('') //model of the item
    const [uploadBrand, setUploadBrand] = useState('') //brand of the item
    const [uploadCategory, setUploadCategory] = useState('154') //category of the item
    const [uploadStockImages, setUploadStockImages] = useState([]) //stock images of the item
    const [uploadFinalImages, setUploadFinalImages] = useState([]) //final images of the item
    const [uploadCategories, setUploadCategories] = useState([
        {
            label: 'Lawn & Garden',
            items:[
                {label: 'Lawn & Garden', value: 149},
                {label: 'Landscaping & Garden Plants', value: 211},
                {label: 'Mowers & Snow Blowers', value: 212},
                {label: 'Power Equipment & Hand Tools', value: 213},

            ]
        },
        {
            label: 'Agriculture & Farm',
            items:[
                {label: 'Agriculture & Farm', value: 130},
                {label: 'Implements, Attachments & Supplies', value: 174},
                {label: 'Tractors & Equipment', value: 292}
            ]
        },
        {
            label: 'Aircraft',
            items:[
                {label: 'Aircraft', value: 131},
                {label: 'Equipment', value: 293},
                {label: 'Supplies', value: 294}
            ]
        },
        {
            label: 'Ammunition',
            items:[
                {label: 'Ammunition', value: 132},
            ]
        },
        {
            label: 'Appliances (Large)',
            items:[
                {label: 'Residential', value: 284},
                {label: 'Appliances (Large)', value: 133},
                {label: 'Commericial & Restaurant', value: 283},
            ]
        },
        {
            label: 'Autos',
            items:[
                {label: 'Autos', value: 295},
                {label: 'Antique, Classic, Collectable, Vintage cars & trucks', value: 298},
                {label: 'Cars, Pickups, SUVS', value: 296},
                {label: 'Parts & Accessories', value: 297},
            ]
        },
        {
            label: 'Construction & Building',
            items:[
                {label: 'Materials', value: 183},
                {label: 'Construction & Building', value: 138},
            ]
        },
        {
            label: 'Construction Equipment',
            items:[
                {label: 'Heavy: Attachments', value: 184},
                {label: 'Heavy: Construction Equipment: Heavy', value: 139},
                {label: 'Heavy: Construction Forklifts', value: 185},
                {label: 'Heavy: Excavators', value: 186},
                {label: 'Heavy: Loaders', value: 187},
                {label: 'Heavy: Skid Steers', value: 188},
            ]
        },
        {
            label: 'Electronics',
            items:[
                {label: 'Electronics', value: 140},
                {label: 'TVs', value: 193},
                {label: 'DVD Players, MP3 Players, Stereos', value: 191},
                {label: 'Arcade', value: 189},
                {label: 'Cameras', value: 190},
                {label: 'Mobile Devices', value: 192},
            ]
        },
        {
            label: 'Equine & Livestock',
            items:[
                {label: 'Equipment & Supplies: Equine & Livestock: Equipment & Supplies', value: 141},
            ]
        },
        {
            label: 'Estate & Personal Property',
            items:[
                {label: 'Estate & Personal Property', value: 142},
                {label: 'Furniture & Décor', value: 197},
                {label: 'Handbags, Accessories, Shoes', value: 196},
                {label: 'Uniforms, Clothing', value: 195},
                {label: 'Wedding', value: 194},
            ]
        },
        {
            label: 'Art & Collectibles',
            items:[
                {label: 'Art & Collectibles', value: 134},
            ]
        },
        {
            label: 'Benefit & Charity',
            items:[
                {label: 'Benefit & Charity', value: 136},
            ]
        },
        {
            label: 'Coins & Currency',
            items:[
                {label: 'Bullion, Precious Metals: Coins & Currency: Bullion, Precious Metals', value: 137},
            ]
        },
        {
            label: 'Events, Tickets, etc.',
            items:[
                {label: 'Events, Tickets, etc.', value: 307},
            ]
        },
        {
            label: 'Firearms',
            items:[
                {label: 'Firearms', value: 299},
                {label: 'Hand Guns', value: 260},
                {label: 'Long Guns', value: 199},
                {label: 'Parts & Accessories', value: 263},
            ]
        },
        {
            label: 'Gym & Exercise',
            items:[
                {label: 'Bicycles', value: 201},
                {label: 'Exercise Equipment', value: 203},
                {label: 'Gym & Exercise', value: 144},
                {label: 'Miscellaneous', value: 202},
            ]
        },
        {
            label: 'Housewares',
            items:[
                {label: 'Housewares', value: 145},
                {label: 'Small Appliances 🔪', value: 285},
                {label: 'Supplies', value: 204},
            ]
        },
        {
            label: 'Hunting & Sporting Goods',
            items:[
                {label: 'Hunting & Sporting Goods', value: 146},
                {label: 'Hunting Supplies', value: 206},
            ]
        },
        {
            label: 'Industrial & Factory',
            items:[
                {label: 'Industrial & Factory', value: 147},
                {label: 'Generators, Pumps, Power Washers, Etc.', value: 271},
                {label: 'Machinery & Equipment', value: 207},
                {label: 'Parts & Accessories', value: 208},
            ]
        },
        {
            label: 'Infant & Baby Items',
            items:[
                {label: 'Infant & Baby Items', value: 290},
            ]
        },
        {
            label: 'Jewelry',
            items:[
                {label: 'Gemstones', value: 287},    
                {label: 'Jewelry', value: 148},
                {label: 'Rings, necklaces, bracelets, watches, etc.', value: 286}
            ]
        },
        {
            label: 'Manufactured Homes & Buildings',
            items:[
                {label: 'Manufactured Homes & Buildings', value: 150},
            ]
        },
        {
            label: 'Material Handling',
            items:[
                {label: 'Material Handling', value: 151},
                {label: 'Carts', value: 216},
                {label: 'Shelving', value: 214},
                {label: 'Shipping & Handling', value: 217},
                {label: 'Storage', value: 215},
            ]
        },
        {
            label: 'Mechanic & Garagekeeper: Equipment',
            items:[
                {label: 'Mechanic & Garagekeeper: Equipment', value: 152},
                {label: 'Miscellaneous', value: 221},
                {label: 'Lifts', value: 219},
                {label: 'Tire Changers', value: 218},
            ]
        },
        {
            label: 'Medical: Equipment & Supplies',
            items:[
                {label: 'Medical: Equipment & Supplies', value: 153},
                {label: 'Equipment', value: 222},
                {label: 'Supplies', value: 223},
            ]
        },
        {
            label: 'Miscellaneous',
            items:[
                {label: 'Miscellaneous', value: 154},
            ]
        },
        {
            label: 'Motorcycle, ATV, UTV',
            items:[
                {label: 'Motorcycle, ATV, UTV', value: 302},
                {label: 'Parts & Accessories', value: 227},
            ]
        },
        {
            label: 'Musical Instruments & Supplies',
            items:[
                {label: 'Musical Instruments & Supplies', value: 156},
            ]
        },
        {
            label: 'Office & Computers',
            items:[
                {label: 'Office & Computers', value: 157},
                {label: 'Equipment', value: 300},
                {label: 'Supplies', value: 301},
                {label: 'Furniture', value: 230},
            ]
        },
        {
            label: 'Outdoor Living: Patio, Hot Tub, Grills, Recreation',
            items:[
                {label: 'Outdoor Living: Patio, Hot Tub, Grills, Recreation', value: 158},
            ]
        },
        {
            label: 'Pallet Loads',
            items:[
                {label: 'Pallet Loads', value: 159},
            ]
        },
        {
            label: 'Party Equipment',
            items:[
                {label: 'Party Equipment', value: 160},
            ]
        },
        {
            label: 'Pet Supplies',
            items:[
                {label: 'Pet Supplies', value: 288},
            ]
        },
        {
            label: 'Real Estate',
            items:[
                {label: 'Real Estate', value: 161},
                {label: 'Commercial/Industrial', value: 272},
                {label: 'Commercial/Industrial Vacant', value: 274},
                {label: 'Agriculture', value: 275},
                {label: 'Recreational', value: 277},
                {label: 'Recreational Vacant', value: 280},
                {label: 'Multi-Dwelling', value: 276},
                {label: 'Residential', value: 278},
                {label: 'Residential Vacant', value: 281},
                {label: 'Waterfront', value: 279},
                {label: 'Waterfront Vacant', value: 282},
            ]
        },
        {
            label: 'Recreational Vehicles',
            items:[
                {label: 'Recreational Vehicles', value: 162},
                {label: 'Motorhomes', value: 240},
                {label: '5th Wheels, Campers, Trailers', value: 262},
                {label: 'Parts & Accessories', value: 241},
            ]
        },
        {
            label: 'Restaurant',
            items:[
                {label: 'Restaurant', value: 163},
                {label: 'Equipment', value: 242},
                {label: 'Dinnerware & Smallwares', value: 243},
                {label: 'Décor', value: 244},
            ]
        },
        {
            label: 'Salon & Tanning',
            items:[
                {label: 'Salon & Tanning', value: 164},
                {label: 'Equipment', value: 245},
                {label: 'Supplies', value: 246},
            ]
        },
        {
            label: 'Security & Surveillance: Equipment',
            items:[
                {label: 'Security & Surveillance: Equipment', value: 165},
            ]
        },
        {
            label: 'Snow Removal (Heavy)',
            items:[
                {label: 'Snow Removal (Heavy)', value: 166},
                {label: 'Equipment & Attachments', value: 247},
            ]
        },
        {
            label: 'Tools',
            items:[
                {label: 'Tools', value: 167},
                {label: 'Power & Hand Tools', value: 248},
                {label: 'Storage', value: 249},
            ]
        },
        {
            label: 'Toys, Hobbies & Home Entertainment',
            items:[
                {label: 'Toys, Hobbies & Home Entertainment', value: 168},
            ]
        },
        {
            label: 'Trailers',
            items:[
                {label: 'Trailers', value: 306},
                {label: 'Semi & Heavy Duty Trailers', value: 252},
                {label: 'Cargo, Utility, Flatbeds, Dump, Specialty', value: 291},
                {label: 'Parts & Accessories', value: 251},
            ]
        },
        {
            label: 'Travel & Leisure',
            items:[
                {label: 'Travel & Leisure', value: 170},
                {label: 'Luggage', value: 253},
                {label: 'Miscellaneous', value: 254},
            ]
        },
        {
            label: 'Trucks',
            items:[
                {label: 'Trucks', value: 171},
                {label: 'Sweeper & Garbage', value: 256},
                {label: 'Semi & Heavy Duty Trucks', value: 303},
                {label: 'Parts & Accessories', value: 309},
            ]
        },
        {
            label: 'Vintage & Antique',
            items:[
                {label: 'Vintage & Antique', value: 172}
            ]
        },
        {
            label: 'Watercraft',
            items:[
                {label: 'Watercraft', value: 173},
                {label: 'Boats, Yachts, Sailboats, Cruisers, Jet Skis, Barges', value: 301},
                {label: 'Parts & Accessories', value: 258},
            ]
        }
    ])
    const [uploadLocation, setUploadLocation] = useState('') //location of the item OPTIONAL

    const [lotSearch, setLotSearch] = useState('')
    
    //Locate Page States
    const [locateLotNumber, setLocateLotNumber] = useState('')
    const [locateInput, setLocateInput] = useState('')
    const [locatePallet, setLocatePallet] = useState('')
    const [locateDimPage, setLocateDimPage] = useState(false)
    const [locateDimLength, setLocateDimLength] = useState('')
    const [locateDimWidth, setLocateDimWidth] = useState('')
    const [locateDimHeight, setLocateDimHeight] = useState('')
    const [locateDimWeight, setLocateDimWeight] = useState('')
    const [locatePalletInput, setLocatePalletInput] = useState('')
    const [locatePrevious, setLocatePrevious] = useState([])
    const [locatePalletCheck, setLocatePalletCheck] = useState([])
    const [locatePalletCheckDialog, setLocatePalletCheckDialog] = useState(false)
    //Order Page States
    const [orders, setOrders] = useState([])

    // View/Edit Page States
    const [viewLots, setViewLots] = useState([])
    const [viewSortedLots, setViewSortedLots] = useState([])
    const [viewSearch, setViewSearch] = useState('');
    const [viewLotStatusSearch, setViewLotStatusSearch] = useState('All');
    const [viewSort, setViewSort] = useState('');
    const [viewPalletSearch, setViewPalletSearch] = useState('');
    const [viewEditPage, setViewEditPage] = useState(false);
    const [viewEditUploader, setViewEditUploader] = useState(''); //username of the uploader
    const [viewEditLotNumber, setViewEditLotNumber] = useState('');
    const [viewEditPallet, setViewEditPallet] = useState('');
    const [viewEditLocation, setViewEditLocation] = useState('');
    const [viewEditTitle, setViewEditTitle] = useState('');
    const [viewEditDescription, setViewEditDescription] = useState('');
    const [viewEditBrand, setViewEditBrand] = useState('');
    const [viewEditModel, setViewEditModel] = useState('');
    const [viewEditConsignor, setViewEditConsignor] = useState('');
    const [viewEditColor, setViewEditColor] = useState('');
    const [viewEditCategory, setViewEditCategory] = useState('');
    const [viewEditCondition, setViewEditCondition] = useState('');
    const [viewEditMissing, setViewEditMissing] = useState([]);
    const [viewEditMissingOptions, setViewEditMissingOptions] = useState([]);
    const [viewEditMissingCustom, setViewEditMissingCustom] = useState('');
    const [viewEditStatus, setViewEditStatus] = useState('');
    const [viewEditImages, setViewEditImages] = useState([]);
    const [viewEditQuantity, setViewEditQuantity] = useState(1);
    const [viewEditFlagged, setViewEditFlagged] = useState(false);
    const [wasFlagged, setWasFlagged] = useState(false); // check the difference for when flagged changes to unflagged
    const [viewEditUpc, setViewEditUpc] = useState('');
    const [viewImportDialog, setViewImportDialog] = useState(false);
    const [lotSearchResultCount, setLotSearchResultCount] = useState(10);
    const [viewEditLot, setViewEditLot] = useState({});

    //listing upload 
    const [viewEditListingDialog, setViewEditListingDialog] = useState(false);
    const [viewEditPrice, setViewEditPrice] = useState(0);
    const [viewEditShipping, setViewEditShipping] = useState(0);

    //SOUNDS STATES
    const [successSound, setSuccessSound] = useState(new Audio(success));
    const [errorSound, setErrorSound] = useState(new Audio(error));
    const [sentSound, setSentSound] = useState(new Audio(sent));
    const [trashSound, setTrashSound] = useState(new Audio(trash));
    const [shutterSound, setShutterSound] = useState(new Audio(shutter));
    const [messageSound, setMessageSound] = useState(new Audio(alertSound));


    //STATES END
    

    //FUNCTIONS

    function showMsg (severity, summary, message) {

        
        if (severity) {
            switch (severity) {
                case 'success':
                    successSound.play().catch((err)=>{console.log(err)});
                    break;
                case 'error':
                    errorSound.play().catch((err)=>{console.log(err)});
                    break;
                case 'sent':
                    sentSound.play().catch((err)=>{console.log(err)});
                    break;
                case 'trash':
                    trashSound.play().catch((err)=>{console.log(err)});
                    break;
                case 'shutter':
                    shutterSound.play().catch((err)=>{console.log(err)});
                    break;
                case 'alert':
                    messageSound.play().catch((err)=>{console.log(err)});
                    break;
                default:
                    successSound.play().catch((err)=>{console.log(err)});
                    break;
            }
        }

        if (severity && summary && message && severity !== 'sent' && severity !== 'trash' && severity !== 'shutter') {
            msgs.current.show({ severity: severity, summary: summary, detail: message});
        }
        
        if (message) {
            useLib.speak(message, 1);
        }
    };
    

    function resetViewSearch () {
        setViewSortedLots(viewLots);
        setViewSort('');
        setViewLotStatusSearch('All');
    }
    function uploadWipe () {
        showMsg('trash')
        setUploadStep(1);
        setUploadLotNumber('');
        setUploadLocation('');
        setUploadImages([]);
        setUploadCondition('');
        setUploadCustomCondition('');
        setUploadMissing([]);
        setUploadCustomMissing('');
        setUploadStatus('');
        setUploadCustomStatus('');
        setUploadUpc('');
        setUploadSearch('');
        setUploadSearchResults([]);
        setUploadUpcResults([]);
        setUploadQuantity(1);
        setUploadTitle('');
        setUploadDescription('');
        setUploadColor('');
        setUploadModel('NA');
        setUploadBrand('');
        setUploadCategory('');
        setUploadStockImages([]);
        setUploadFinalImages([]);
    }

    function confirmAction (action) {
        setUploadConfirmMenu(true);
        setUploadConfirmAction(action);
    }

    function getBase64ImageResolution(base64Image) {
        const img = new Image();
        img.src = base64Image;
      
        const width = img.width;
        const height = img.height;
      
        return { width, height };
      }



      const handleFileDrop = async (files, strings) => {
        const file = files[0];
        let newImageData = null;
    
        // Convert file to Base64
        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };
    
        // Check if a string is a valid URL
        const isValidURL = (string) => {
            try {
                new URL(string);
                return true;
            } catch (_) {
                return false;  
            }
        };
    
        if (file) {
            // Check if the file type is AVIF
            if (file.type === 'image/avif') {
                // Convert AVIF to Base64
                newImageData = await convertToBase64(file);
            } else if (file.type.startsWith('image/')) {
                // For other image types, use the original approach (JPG, PNG, etc.)
                newImageData = await convertToBase64(file);
            }
        } else {
            const url = strings[0];
            if (isValidURL(url)) {
                newImageData = url;
            }
        }
    
        if (newImageData) {
            setViewEditImages([newImageData, ...viewEditImages]);
        }
    };

    function locateLot(lot, location, successMsg, dims = {}) {
        if (dims.width != undefined && dims.height != undefined && dims.length != undefined && dims.weight != undefined) {
            axios.put('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/locate', {lotNumber: lot, locationNumber: location, user: username, lotWeight: dims.weight, lotHeight: dims.height, lotWidth: dims.width, lotLength: dims.length}).then((res)=>{
            showMsg('success', null, successMsg)
            // sendFeedAlert('Located', lot)
            }).catch((err)=>{
                showMsg('error', null, "Couldn't Locate Lot!")
                setLocateInput('');
            });
        
        }else{
            axios.put('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/locate', {lotNumber: lot, locationNumber: location, user: username}).then((res)=>{
            showMsg('success', null, successMsg)
            // sendFeedAlert('Located', lot)
            }).catch((err)=>{
                showMsg('error', null, "Couldn't Locate Lot!")
                setLocateInput('');
            });
        }
        
        return true;
    }

    function createLot (flagged, allowBlank = false) {
        let errors = [];
        let currentPage = page;
        
        if (!uploadQuantity || uploadQuantity < 1) {
            errors.push('Quantity must be atleast 1!')
        }
        // if (!uploadBrand) {
        //     errors.push('Brand is required!')
        // }
        if (!uploadModel) {
            errors.push('Model is required!')
        }

        if (allowBlank === false) {
            if (!uploadCategory) {
                errors.push('Category is required!')
            }
            if (!uploadTitle) {
                errors.push('Title is required!')
            }
            if (!uploadDescription) {
                errors.push('Description is required!')
            }
        }
        
        if (!uploadCondition) {
            errors.push('Condition is required!')
        }
        if (!uploadStatus) {
            errors.push('Testing Status is required!')
        }
        
        // if (!uploadColor) {
        //     errors.push('Color is required!')
        // }
        if (!uploadImages || uploadImages.length < 1) {
            errors.push('You must upload atleast 1 images!')
        }
        if (errors.length > 0) {
            if (errors.length > 1) {
                showMsg('error', 'ERROR', errors.length+' Errors!')
            }else{
                showMsg('error', 'ERROR', errors[0])
            }
        }else{
            // search for lot with the same lot number
            // use the query called lotNumber
            axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/get?lotNumber='+uploadLotNumber).then((res)=>{
                if (res.data.lotNumber === uploadLotNumber) {
                    if (window.confirm('Lot Number already exists! Do you want to overwrite the lot information?')) {
                    
                    

                        setPage('Uploading Images');
                        axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/addImages', {lotnumber: uploadLotNumber, images: uploadFinalImages}).then((res)=>{
                            setPage('Uploading Lot');
                            let lot = {};
                            if (allowBlank) {
                                lot = {
                                    lotNumber: uploadLotNumber,
                                    palletNumber: pallet,
                                    upc: uploadUpc,
                                    title: uploadModel,
                                    description: uploadModel+ ' | ' + uploadDescription,
                                    brand: uploadBrand,
                                    model: uploadModel,
                                    color: uploadColor,
                                    category: uploadCategory,
                                    condition: uploadCondition,
                                    missing: uploadMissing,
                                    status: uploadStatus,
                                    images: res.data,
                                    stockImages: uploadFinalImages.filter(item => !uploadImages.includes(item)),
                                    quantity: uploadQuantity,
                                    user: username,
                                    flagged: flagged
                                }
                            }else{
                                lot = {
                                    lotNumber: uploadLotNumber,
                                    palletNumber: pallet,
                                    upc: uploadUpc,
                                    title: uploadTitle,
                                    description: uploadDescription,
                                    brand: uploadBrand,
                                    model: uploadModel,
                                    color: uploadColor,
                                    category: uploadCategory,
                                    condition: uploadCondition,
                                    missing: uploadMissing,
                                    status: uploadStatus,
                                    images: res.data,
                                    stockImages: uploadFinalImages.filter(item => !uploadImages.includes(item)),
                                    quantity: uploadQuantity,
                                    user: username,
                                    flagged: flagged
                                }
                            }
                            
                            console.log(lot)
                            
                            if (uploadConsignor) {
                                lot.consignor = uploadConsignor;
                            }
                            axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/create', lot).then(async (res)=>{
                                if (flagged) {
                                    if (atWarehouse) { // if at warehouse restart upload process, otherwise go direct to next page to locate direct instead of using palletd
                                        uploadWipe();
                                        setUploadStep(1);
                                    }else{
                                        setLocateDimPage(true); // open the dimensions page so that dims can be entered for this instance of locating
                                        setUploadStep(uploadStep+1);
                                    }
                                    
                                    setPage(currentPage);
                                    // sendFeedAlert('Uploaded', uploadLotNumber)
                                    showMsg('success', null, 'Lot Uploaded Successfully!')
                                }else{
                                    axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/fixTicket/create', {lotNumber: uploadLotNumber, useOcr: false, priority: false, status: 'pending'}).then((res)=>{
                                        if (atWarehouse) { // if at warehouse restart upload process, otherwise go direct to next page to locate direct instead of using palletd
                                            uploadWipe();
                                            setUploadStep(1);
                                        }else{
                                            setLocateDimPage(true); // open the dimensions page so that dims can be entered for this instance of locating
                                            setUploadStep(uploadStep+1);
                                        }
                                        
                                        setPage(currentPage);
                                        // sendFeedAlert('Uploaded', uploadLotNumber)
                                        showMsg('success', null, 'Lot Uploaded Successfully!')
                                    }).catch((err)=>{
                                        setPage(currentPage);
                                        showMsg('error', 'ERROR', 'SERVER ERROR')
                                    });
                                }
                                setPage(currentPage);
                                // sendFeedAlert('Uploaded', uploadLotNumber)
                                showMsg('success', null, 'Lot Uploaded Successfully!')
                                
                            }).catch((err)=>{
                                setPage(currentPage);
                                showMsg('error', 'ERROR', err.response.data)
                            });
                        }).catch((err)=>{
                            setPage(currentPage);
                            showMsg('error', 'ERROR', err.response.data)
                        });
                    }else{
                        setPage(currentPage);
                        return;
                    }


                }else{
                    

                    setPage('Uploading Images');
                        axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/addImages', {lotnumber: uploadLotNumber, images: uploadFinalImages}).then((res)=>{
                            setPage('Uploading Lot');
                            let lot = {};
                            if (allowBlank) {
                                lot = {
                                    lotNumber: uploadLotNumber,
                                    palletNumber: pallet,
                                    upc: uploadUpc,
                                    title: uploadModel,
                                    description: uploadModel+ ' | ' + uploadDescription,
                                    brand: uploadBrand,
                                    model: uploadModel,
                                    color: uploadColor,
                                    category: uploadCategory,
                                    condition: uploadCondition,
                                    missing: uploadMissing,
                                    status: uploadStatus,
                                    images: res.data,
                                    stockImages: uploadFinalImages.filter(item => !uploadImages.includes(item)),
                                    quantity: uploadQuantity,
                                    user: username,
                                    flagged: flagged
                                }
                            }else{
                                lot = {
                                    lotNumber: uploadLotNumber,
                                    palletNumber: pallet,
                                    upc: uploadUpc,
                                    title: uploadTitle,
                                    description: uploadDescription,
                                    brand: uploadBrand,
                                    model: uploadModel,
                                    color: uploadColor,
                                    category: uploadCategory,
                                    condition: uploadCondition,
                                    missing: uploadMissing,
                                    status: uploadStatus,
                                    images: res.data,
                                    stockImages: uploadFinalImages.filter(item => !uploadImages.includes(item)),
                                    quantity: uploadQuantity,
                                    user: username,
                                    flagged: flagged
                                }
                            }
                            
                            console.log(lot)
                            
                            if (uploadConsignor) {
                                lot.consignor = uploadConsignor;
                            }
                            axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/create', lot).then(async (res)=>{
                                if (flagged) {
                                    if (atWarehouse) { // if at warehouse restart upload process, otherwise go direct to next page to locate direct instead of using palletd
                                        uploadWipe();
                                        setUploadStep(1);
                                    }else{
                                        setLocateDimPage(true); // open the dimensions page so that dims can be entered for this instance of locating
                                        setUploadStep(uploadStep+1);
                                    }
                                    
                                    setPage(currentPage);
                                    // sendFeedAlert('Uploaded', uploadLotNumber)
                                    showMsg('success', null, 'Lot Uploaded Successfully!')
                                }else{
                                    axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/fixTicket/create', {lotNumber: uploadLotNumber, useOcr: false, priority: false, status: 'pending'}).then((res)=>{
                                        if (atWarehouse) { // if at warehouse restart upload process, otherwise go direct to next page to locate direct instead of using palletd
                                            uploadWipe();
                                            setUploadStep(1);
                                        }else{
                                            setLocateDimPage(true); // open the dimensions page so that dims can be entered for this instance of locating
                                            setUploadStep(uploadStep+1);
                                        }
                                        
                                        setPage(currentPage);
                                        // sendFeedAlert('Uploaded', uploadLotNumber)
                                        showMsg('success', null, 'Lot Uploaded Successfully!')
                                    }).catch((err)=>{
                                        setPage(currentPage);
                                        showMsg('error', 'ERROR', 'SERVER ERROR')
                                    });
                                }
                                setPage(currentPage);
                                // sendFeedAlert('Uploaded', uploadLotNumber)
                                showMsg('success', null, 'Lot Uploaded Successfully!')
                                
                            }).catch((err)=>{
                                setPage(currentPage);
                                showMsg('error', 'ERROR', err.response.data)
                            });
                        }).catch((err)=>{
                            setPage(currentPage);
                            showMsg('error', 'ERROR', err.response.data)
                        });


                }
            }).catch((err)=>{
                setPage('Uploading Images');
                        axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/addImages', {lotnumber: uploadLotNumber, images: uploadFinalImages}).then((res)=>{
                            setPage('Uploading Lot');
                            let lot = {};
                            if (allowBlank) {
                                lot = {
                                    lotNumber: uploadLotNumber,
                                    palletNumber: pallet,
                                    upc: uploadUpc,
                                    title: uploadModel,
                                    description: uploadModel+ ' | ' + uploadDescription,
                                    brand: uploadBrand,
                                    model: uploadModel,
                                    color: uploadColor,
                                    category: uploadCategory,
                                    condition: uploadCondition,
                                    missing: uploadMissing,
                                    status: uploadStatus,
                                    images: res.data,
                                    stockImages: uploadFinalImages.filter(item => !uploadImages.includes(item)),
                                    quantity: uploadQuantity,
                                    user: username,
                                    flagged: flagged
                                }
                            }else{
                                lot = {
                                    lotNumber: uploadLotNumber,
                                    palletNumber: pallet,
                                    upc: uploadUpc,
                                    title: uploadTitle,
                                    description: uploadDescription,
                                    brand: uploadBrand,
                                    model: uploadModel,
                                    color: uploadColor,
                                    category: uploadCategory,
                                    condition: uploadCondition,
                                    missing: uploadMissing,
                                    status: uploadStatus,
                                    images: res.data,
                                    stockImages: uploadFinalImages.filter(item => !uploadImages.includes(item)),
                                    quantity: uploadQuantity,
                                    user: username,
                                    flagged: flagged
                                }
                            }
                            
                            console.log(lot)
                            
                            if (uploadConsignor) {
                                lot.consignor = uploadConsignor;
                            }
                            axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/create', lot).then(async (res)=>{
                                if (flagged) {
                                    if (atWarehouse) { // if at warehouse restart upload process, otherwise go direct to next page to locate direct instead of using palletd
                                        uploadWipe();
                                        setUploadStep(1);
                                    }else{
                                        setLocateDimPage(true); // open the dimensions page so that dims can be entered for this instance of locating
                                        setUploadStep(uploadStep+1);
                                    }
                                    
                                    setPage(currentPage);
                                    // sendFeedAlert('Uploaded', uploadLotNumber)
                                    showMsg('success', null, 'Lot Uploaded Successfully!')
                                }else{
                                    axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/fixTicket/create', {lotNumber: uploadLotNumber, useOcr: false, priority: false, status: 'pending'}).then((res)=>{
                                        if (atWarehouse) { // if at warehouse restart upload process, otherwise go direct to next page to locate direct instead of using palletd
                                            uploadWipe();
                                            setUploadStep(1);
                                        }else{
                                            setLocateDimPage(true); // open the dimensions page so that dims can be entered for this instance of locating
                                            setUploadStep(uploadStep+1);
                                        }
                                        
                                        setPage(currentPage);
                                        // sendFeedAlert('Uploaded', uploadLotNumber)
                                        showMsg('success', null, 'Lot Uploaded Successfully!')
                                    }).catch((err)=>{
                                        setPage(currentPage);
                                        showMsg('error', 'ERROR', 'SERVER ERROR')
                                    });
                                }
                                setPage(currentPage);
                                // sendFeedAlert('Uploaded', uploadLotNumber)
                                showMsg('success', null, 'Lot Uploaded Successfully!')
                                
                            }).catch((err)=>{
                                setPage(currentPage);
                                showMsg('error', 'ERROR', err.response.data)
                            });
                        }).catch((err)=>{
                            setPage(currentPage);
                            showMsg('error', 'ERROR', err.response.data)
                        });
            });
        }

        
    }

    function updateLot () {
        let errors = [];
        
        if (viewEditQuantity < 1 || !viewEditQuantity) {
            errors = ['Quantity must be atleast 1!' ,...errors]
        }
        if (!viewEditModel) {
            errors = ['Model is required!' ,...errors]
        }
        if (!viewEditCondition) {
            errors = ['Condition is required!' ,...errors ,...errors]
        }
        if (!viewEditStatus) {
            errors = ['Testing Status is required!' ,...errors]
        }
        if (!viewEditTitle) {
            errors = ['Title is required!' ,...errors]
        }
        if (!viewEditDescription) {
            errors = ['Description is required!' ,...errors]
        }
        if (!viewEditImages || viewEditImages.length < 1) {
            errors = ['There must be atleast 1 images!' ,...errors]
        }
        if (!useLib.parseLoc(viewEditLocation) && !useLib.parsePallet(viewEditPallet)) {
            errors = ['Location is invalid!' ,...errors]
        }
        if (!useLib.parseLot(viewEditLotNumber)) {
            errors = ['Lot Number is invalid!' ,...errors]
        }
        if (!useLib.parsePallet(viewEditPallet)) {
            if (viewEditPallet !== '') {
                errors = ['Pallet Number is invalid!' ,...errors]
            }   
        }
        if (errors.length > 0) {
            if (errors.length > 1) {
                showMsg('error', null, errors.length+' Errors!')
            }else{
                showMsg('error', null, errors[0])
            }
        }else{
            if (wasFlagged && !viewEditFlagged) { // check if flagged was changed to unflagged
                axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/fixTicket/create', {lotNumber: viewEditLotNumber, useOcr: false, priority: false, status: 'pending'}).then((res)=>{
                }).catch((err)=>{
                    console.log(err)
                });
            }
            
            let currentPage = page;
            setPage('Updating Lot');
            axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/addImages', {lotnumber: viewEditLotNumber, images: [viewEditImages[0]]}).then((imgres)=>{
                axios.put('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/update', {
                    lotNumber: viewEditLotNumber,
                    palletNumber: viewEditPallet,
                    locationNumber: viewEditLocation,
                    upc: viewEditUpc,
                    title: viewEditTitle,
                    description: viewEditDescription,
                    brand: viewEditBrand,
                    model: viewEditModel,
                    color: viewEditColor,
                    category: viewEditCategory,
                    condition: viewEditCondition,
                    missing: viewEditMissing,
                    status: viewEditStatus,
                    // remove first image from array because it is already added
                    images: [...imgres.data, ...viewEditImages.slice(1)],
                    quantity: viewEditQuantity,
                    flagged: viewEditFlagged
                }).then((res)=>{
                    setPage(currentPage);
                    showMsg('success', null, 'Lot Updated Successfully!')
                    // sendFeedAlert('Updated', viewEditLotNumber)
                    if (typeof(viewEditPage) !== false) {
                        let lot = viewSortedLots[viewEditPage];
                        lot.lotNumber = viewEditLotNumber;
                        lot.palletNumber = viewEditPallet;
                        lot.locationNumber = viewEditLocation;
                        lot.upc = viewEditUpc;
                        lot.title = viewEditTitle;
                        lot.description = viewEditDescription;
                        lot.brand = viewEditBrand;
                        lot.model = viewEditModel;
                        lot.color = viewEditColor;
                        lot.category = viewEditCategory;
                        lot.condition = viewEditCondition;
                        lot.missing = viewEditMissing;
                        lot.status = viewEditStatus;
                        lot.images = viewEditImages;
                        lot.quantity = viewEditQuantity;
                        lot.flagged = viewEditFlagged;
                        setViewSortedLots(prev => prev.map(item => item.lotNumber === lot.lotNumber ? lot : item));

                    }
                    setViewEditPage(false);
                }).catch((e)=>{
                    showMsg('error', null, e.response.data)
                    setPage(currentPage);
                });
            }).catch((err)=>{
                setPage(currentPage);
                showMsg('error', null, err.response.data)
            });
        }
    }

    function login(code) {
        
        
        let Return = true;
        setPage('Validating Code')
        axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/login', {code: code}).then((res)=>{
            setPage(1)
            setUsername(res.data)
            const tempUsername = res.data;
            // get number of lots where uploadedBy is the user and createdAt is today
            axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/search', {
                params: {
                    search: '',
                    count: 1000,
                    searchQuery: {
                    uploadedBy: tempUsername, 
                    timeUploaded: {
                        "$gte": new Date(new Date().setHours(0, 0, 0, 0))
                        }
                    }
                }
            }).then((res)=>{
                setUploadsToday(res.data.length);
            }).catch((err)=>{
                console.log(err)
            });

            //get number of lots that where located by the user and createdAt is today
            axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/search', {
                params: {
                    search: '',
                    count: 1000,
                    searchQuery: {
                    locatedBy: tempUsername, 
                    timeLocated: {
                        "$gte": new Date(new Date().setHours(0, 0, 0, 0))
                        }
                    }
                }
            }).then((res)=>{
                setLotsLocatedToday(res.data.length);
            }).catch((err)=>{
                console.log(err)
            });
            Return = true;
            // const elem = document.documentElement;
            // if (!document.fullscreenElement && !document.webkitFullscreenElement) {
            //     openFullscreen(elem);
            // }
            showMsg('success', null, 'Hello '+res.data+'!')
        }).catch((err)=>{
            showMsg('error', null, 'Invalid Code! Please Try Again!')
            setPage(0)
        });
        return Return;
    }

    function searchUpc (keyword) {
        axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/searchupc', {
            params: {
            search: keyword
            }
        })
        .then(response => {
            setUploadSearchResults(response.data.filter(item => item !== null));
            console.log(response)
        })
        .catch(error => {
            console.log(error);
            showMsg('error', 'ERROR', error.response.data)
        });
    }

    function getUpc (upc, nextpage=false) {
        let currentPage = page;
        setPage('Getting Product Info')
        axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/getupc', {
            params: {
            upc: upc
            }
        })
        .then(async response => {
            let info = response.data;
            if (info) {
                showMsg('success', null, 'Product Found!')
                setPage(currentPage)
                setUploadStep(7)
                setUploadStockImages(info.images)
                setUploadFinalImages([...info.images, ...uploadImages].filter(Boolean))
                setUploadUpcResults(info);
                setUploadTitle(info.title[0] || (typeof(info.title) !='object' && info.title));
                setUploadDescription(info.description[0] || (typeof(info.description) !='object' && info.description));
                setUploadBrand(info.brand[0] || (typeof(info.brand) !='object' && info.brand) || info.manufacturer[0] || (typeof(info.manufacturer) !='object' && info.manufacturer));
                setUploadModel(info.model[0] || (typeof(info.model) !='object' && info.model) || info.mpn[0] || (typeof(info.mpn) !='object' && info.mpn));
                setUploadColor(info.color[0] || (typeof(info.color) !='object' && info.color));
                setUploadCategory(info.category);
                setUpdate(!update)
            }else{
                setPage(currentPage)
                setUploadStep(7)
                setUploadFinalImages(uploadImages)
                showMsg('error', 'ERROR', 'No Products Found')

            }
        })
        .catch(error => {
            setPage(currentPage)
            setUploadStep(7)
            setUploadFinalImages(uploadImages)
            if (error.response.data) {
                showMsg('error', 'ERROR', error.response.data)

            }else{
                showMsg('error', 'ERROR', 'Could not find products')
            }
        });

    }

    function searchlot (keyword) {
        if (useLib.parseLot(keyword)) {
            keyword = parseLot(keyword);
        }
        axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/search', {
            params: {
                search: keyword,
                count: lotSearchResultCount,
                status: viewLotStatusSearch
            }
        })
        .then(response => {
            let info = response.data;
                        
            
            if (info.length === 0) {
                showMsg('error', null, 'No Lots Found!')
            }else{
                if (info.length > 1) {
                    setViewLots(info);
                    setViewSortedLots(info);        
                }else{
                    info = info[0];
                    setViewEditLot(info)
                    setViewEditPage(0);
                    setViewEditUpc(info.upc);
                    setViewEditLocation(info.locationNumber);
                    setViewEditFlagged(info.flagged);
                    setWasFlagged(info.flagged);
                    setWasFlagged(info.flagged);
                    setViewEditLotNumber(info.lotNumber);
                    setViewEditPallet(info.palletNumber || info.locationNumber);
                    setViewEditTitle(info.title);
                    setViewEditDescription(info.description);
                    setViewEditBrand(info.brand);
                    setViewEditModel(info.model);
                    setViewEditColor(info.color);
                    setViewEditCategory(info.category);
                    setViewEditQuantity(info.quantity);
                    setViewEditImages(info.images);
                    setViewEditCondition(info.condition);
                    setViewEditMissing(info.missing);
                    setViewEditMissingOptions(info.missing);
                    setViewEditStatus(info.status);
                }
            }
        })
        .catch(error => {
            showMsg('error', null, error.response.data)
        });
    }

    const handleStart = (event) => {
        setStartY(event.touches?.[0]?.clientY || event.clientY);
        setStartX(event.touches?.[0]?.clientX || event.clientX);
    };

    const handleEnd = (event, state, stateFunction, index) => {
        const endY = event.changedTouches?.[0]?.clientY || event.clientY;
        const endX = event.changedTouches?.[0]?.clientX || event.clientX;
        if (startY !== null) {
            if (startY-endY > (.75*event.target.height) && Math.abs(startX-endX) < (.90*event.target.width)) {
                showMsg('sent');
                let newUploadImages = state;
                let current = newUploadImages[index];
                newUploadImages.splice(index, 1);
                stateFunction([current, ...newUploadImages]);
            } else if (endY - startY > (.75*event.target.height) && Math.abs(startX-endX) < (.90*event.target.width)) {
                showMsg('trash');
                let newUploadImages = state;
                newUploadImages.splice(index, 1);
                stateFunction(newUploadImages);
            }
        }
        setUpdate(!update)
    };
    
    function capture () {
        showMsg('shutter');
        const imageSrc = camera.current.captureFrame();
        setUploadImages([...uploadImages, imageSrc])
    };
    
    // OUTDATED SOCKET IO STUFF

    // const sendFeedAlert = (feedMessage, lotNumber) => {
    //     socket.emit('feedAlert', {
    //         message: feedMessage,
    //         lotNumber: lotNumber
    //     }); 
    // }


    //FUNCTIONS END

    useEffect(() => {
        if (selectInput(uploadStep) && document.querySelector(selectInput(uploadStep))) {
            document.querySelector(selectInput(uploadStep)).focus();
        }
    }, [update, page, pallet, uploadImages, uploadStep, uploadLotNumber, uploadUpc])

    useEffect(() => {
        // socket.on('feedMessage', (msg) => {
        //     setFeedMessages([...feedMessages, msg]);
        // });
        window.addEventListener('resize', ()=>{
            setWindowSize([window.innerWidth, window.innerHeight]);
        });

        axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/order/get', {query: {}}).then((res)=>{
            console.log(res.data)
            setOrders(res.data);
        }).catch((err)=>{
            console.log(err)
        });

       
        
    }, [])


    useEffect(() => {
        searchlot(viewSearch)
    }, [viewLotStatusSearch])

    useEffect(() => { // use effect to ensure it is only listening when on the live page
        if (page == 4) {
            // socket.on('feedAlert', (data) => {
            //     if (data.message === 'Uploaded' || data.message === 'Updated' || data.message === 'Located') {
            //         data.time = new Date();
            //         setFeedMessages(prevFeedMessages => [data, ...prevFeedMessages]);

            //         showMsg('alert', null, data.message+" "+data.lotNumber+'!')
            //     }
                
            // });
    
            // return () => {
            //     // Remove WebSocket event listener
            //     socket.off('feedAlert');
            // };

        }
    }, [page])
        

    // MAIN FUNCTION RETURN
    return (
        <div className='w-screen fixed flex flex-column align-items-center h-screen '>
            <Dialog className='w-full mx-2' header="Upload Menu" visible={uploadMenu} onHide={() => setUploadMenu(false)}>
                <div className='flex mt-2 flex-column gap-2'>
                    {/* <form className='p-inputgroup'>
                        <InputText value={lotSearch} onChange={(e)=>{setLotSearch(e.target.value);}} placeholder="Lot Search" />
                        <Button icon="pi pi-search" className="p-button-info" />
                    </form> */}
                    <Button severity='info' label='View / Edit Lots' icon='pi pi-file-edit' onClick={()=>{setPage(5); setUploadMenu(false); uploadWipe(); searchlot('')}} />
                    {
                        atWarehouse &&
                            <Button severity='info' icon="pi pi-box" onClick={()=>{uploadWipe(); setUploadStep(0); setUploadMenu(false)}} label='Change Pallet/Batch'  />
                    }
                    <Button onClick={()=>{setPage(1); uploadWipe(); setUploadMenu(false)}} label='Exit' severity='danger' icon='pi pi-arrow-left' />
                </div>
            </Dialog>
            <Dialog className='w-full mx-2' header="Are you sure?" visible={uploadConfirmMenu} onHide={()=>setUploadConfirmMenu(false)}>
                <div className='flex flex-column align-items-center justify-content-center'>
                    
                    <p>Are you sure you want to do this? IT WILL RESET THE PROGRESS ON THIS LOT!</p>
                    <Button onClick={()=>{setUploadConfirmMenu(false); uploadWipe()}} label='Continue Anyways' />
                </div>
            </Dialog>
            <Dialog visible={locatePalletCheckDialog} onHide={()=>setLocatePalletCheckDialog(false)} header={'Pallet Info'} className=' w-screen h-screen mx-0'>
                <div className='flex flex-column p-2 w-full h-full gap-2'>
                    <form onSubmit={(e)=>{
                        e.preventDefault();
                        if (locatePalletInput) {
                            if (useLib.parsePallet(locatePalletInput)) {
                                axios.get('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/pallet/getLocations?palletNumber='+useLib.parsePallet(locatePalletInput)).then((res)=>{
                                    setLocatePalletCheck(res.data);
                                }).catch((err)=>{
                                })
                                setLocatePalletInput('');
                            }else{
                                setLocatePalletInput('');
                                return showMsg('error', null, 'Invalid Pallet Number!')
                            }
                        }else{
                            return showMsg('error', null, 'No Input!')
                        }
                    }} className='p-inputgroup'>
                        <InputText value={locatePalletInput} onChange={(e)=>{setLocatePalletInput(e.target.value);}} placeholder={"Check Pallet"} />
                        <Button icon="pi pi-check" className="p-button-info"/>
                    </form>
                    <div>{locatePalletCheck.length} Items on Pallet</div>
                    <div className='flex flex-column gap-2'>
                        {
                            locatePalletCheck.map((item, index)=>{
                                return (
                                    <div className='flex gap-2'>
                                        <img className='w-3' src={item.images[0]} />
                                        <div>{item.lotNumber}</div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>
                

            </Dialog>
            {/* Loading Page , Defaults if the page is set to a text value which is used as the loading message */}
            {
                typeof(page) !== 'number' &&
                <div className='gap-5 mt-8 flex flex-column align-items-center'>
                    {/* spinner for when page is loading */}
                    <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
                    <h3>{page}</h3>
                    <a href={window.location.href}><Button label="Cancel/Restart" /></a>
                    <Messages position='top-center' ref={msgs} />
                </div>
            }
            {/* Login page */}
            {   
                page === 0 &&
                <form onSubmit={(e)=>{e.preventDefault(); login(code)}} className='flex flex-column align-items-center gap-5 mt-5'>
                    <img src={logo} alt='5 Dollar Auctions Logo' className='w-6'/>
                    <h1 className='text-lg'>5 Dollar Auctions Uploader</h1>
                    <Password feedback={false} value={code} onChange={(e)=>setCode(e.target.value)} placeholder="Login Code" />
                    <Button label="Login" type='submit' />
                    <ToggleButton checked={atWarehouse} onChange={(e)=>{setAtWarehouse(e.value)}} onLabel='Warehouse' offLabel='Auction House' />
                    <Messages position='top-center' ref={msgs} />
                </form>
            }
            {/* Menu Page */}
            {
                page === 1 &&
                <div className='flex w-full flex-column align-items-center justify-content-between h-full gap-2 p-2'>
                    <div className='flex justify-content-center align-items-center flex-column'>
                        <img src={logo} alt='5 Dollar Auctions Logo' className='w-6'/>
                        <h2 className='text-lg'>Uploader Menu</h2>
                        <span>Hello, {username}!</span>
                    </div>
                    <div className='gap-2 flex flex-column'>
                        <span>Today's Statistics</span>
                        <div className='flex flex-column gap-2'>
                            <span>Uploads: {uploadsToday}</span>
                            <span>Lots Located: {lotsLocatedToday}</span>
                        </div>
                    </div>
                    <div className='flex w-full flex-column gap-2'>
                        <Button className='w-full' severity='info' label='Upload' icon='pi pi-upload' onClick={()=> {
                            setPage(2); 
                            if (!atWarehouse) {
                                setUploadStep(1)
                                setPallet('House') // NOTE!!! THIS IS TO MAKE ALL THE ITEMS UPLOADED AT THE AUCITON HOUSE AVAILABLE AS A PALLET FOR UPLOAD! CHANGE LATER TO UPLOAD LOCATION INSTEAD OF PALLET NUMBER FOR CLARITY
                            }else{
                                setUploadStep(0)
                            }}
                            } />
                        <Button className='w-full' severity='info' label='Locate' icon='pi pi-map-marker' onClick={()=> setPage(3)} />
                        <Button className='w-full' severity='info' label='Ebay' icon='pi pi-wifi' onClick={()=> window.location.href = '/387294738'} />
                        <Button className='w-full' severity='info' label='View/Edit' icon='pi pi-eye' onClick={()=> {setPage(5); searchlot('');}} />
                        <Button className='w-full' severity='danger' label='Logout' icon='pi pi-user' onClick={() => setPage(0)} />
                    </div>
                </div>
            }
            {/* Upload Page */}
            {
                page === 2 &&
                <div className='h-screen w-full flex flex-column'>
                    {
                        uploadStep > 0 &&
                        <div id='uploadNav' className='flex border-solid border-none border-bottom-1 border-400 w-full align-items-center justify-content-between p-2'>
                            <div className='text-lg'>{pallet} : {uploadConsignor ? uploadConsignor : "5DA"}</div>
                            <div className='p-inputgroup w-auto h-2rem'>
                                <Button onClick={()=>{confirmAction()}} label="Reset" icon="pi pi-arrow-left" severity="danger" />
                                <Button onClick={()=>{setUploadMenu(true); }} icon="pi pi-bars" className="w-5rem" severity="info" />
                            </div>
                        </div>
                    }
                    {
                        uploadStep === 0 &&
                        <form 
                            onSubmit={(e)=>{
                                e.preventDefault(); 
                                if (uploadConsignor) {
                                    setUploadConsignor(uploadConsignor.replace(/batch-/i, ''));
                                }
                                if (parsePallet(pallet)) {
                                    setPallet(parsePallet(pallet))
                                    showMsg('success')
                                    setUploadStep(1);
                                }else{
                                    showMsg('error', 'ERROR', 'Invalid Pallet Number' )
                                    setPallet(null);
                                }
                            }} 
                            className='flex flex-column align-items-center justify-content-between gap-5 p-2 pt-5 w-full h-full'
                        >
                            <div className='flex flex-column gap-3'>
                                <h1 className='text-lg'>5 Dollar Auctions Uploader</h1>
                                <div className='p-inputgroup'>
                                    <InputText id="palletInput" value={pallet} onChange={(e)=>setPallet(e.target.value)} label='Enter Pallet Number (pal-0000 or 0000)' placeholder='Pallet Number' />
                                    <Button onClick={()=>{setPallet(null); setUpdate(!update)}} severity='danger' type="button" icon='pi pi-times' />
                                </div>
                                <div>
                                    <div className='text-lg'>Batch Number (Consignor or Pallet From Truck etc...)</div>
                                    <div className='p-inputgroup'>
                                        <InputText id="consignorInput" value={uploadConsignor} onChange={(e)=>setUploadConsignor(e.target.value)} label='Enter Batch Number' placeholder='Batch Number' />
                                        <Button onClick={()=>{setUploadConsignor(''); setUpdate(!update)}} severity='danger' type="button" icon='pi pi-times' />
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            <div className='w-full flex flex-column gap-2'>
                                <Messages position='bottom-center' ref={msgs} />
                                <Button onClick={()=>{setPage(1); setPallet(null)}} className='w-full' severity='danger' icon='pi pi-arrow-left' label="Back" type='button' />
                                <Button className='w-full' label="Continue" type='submit' />
                            </div>
                            
                            
                        </form>
                    }
                    {
                        uploadStep === 1 &&
                        <div className='flex flex-column surface-900 h-full w-screen '>
                            
                            <CameraCapture ref={camera} className={'w-screen'} />
                            <div className='flex flex-column h-full gap-2 p-2' id='galleria'>
                                <div className='flex gap-2 surface-900 align-items-center h-full overflow-scroll'>
                                    {
                                        uploadImages.map((image, index) => {
                                            return image && <img 
                                                className='max-h-11rem border-round'
                                                onTouchStart={(e)=>handleStart(e, uploadImages, setUploadImages)}
                                                onTouchEnd={(e)=>handleEnd(e, uploadImages, setUploadImages, index)}
                                                onMouseDown={(e)=>handleStart(e, uploadImages, setUploadImages)}
                                                onMouseUp={(e)=>handleEnd(e, uploadImages, setUploadImages, index)}
                                                key={index}
                                                src={image} 
                                                alt='Uploaded Image' 
                                            />
                                        })
                                    }
                                </div>
                                
                                <div className='flex z-3 fixed justify-content-between px-3 align-items-center w-full left-0' style={{transform: 'translate(0px, -6rem)'}}>
                                    <Button rounded outlined label={!uploadImages.length?'0':uploadImages.length} severity="info" disabled />
                                    <Button className="outline-none w-5rem h-5rem" icon="pi pi-camera" rounded outlined severity="info" onClick={capture} />
                                    <Button style={!(uploadImages.length > 1) && {visibility:'hidden'}} className="outline-none" icon="pi pi-arrow-right" rounded outlined severity="success" onClick={()=>{setUploadStep(uploadStep+1); showMsg('success')}} />
                                    
                                </div>
                                
                                
                            </div>
                            
                            
                        </div>
                    }
                    {
                        uploadStep === 2 &&
                        <div>
                            <div className='p-2 flex flex-column align-items-center'>
                                <h1 className='text-lg'>Choose The Lots Condition.</h1>
                                <ListBox className='w-full' onChange={(e)=>{
                                    if (!e.value) {
                                        setUploadCondition(null);
                                    }else{
                                        setUploadCondition(e.value);
                                        showMsg('success', null, e.value);
                                        setUploadStep(uploadStep+1)
                                    }
                                    

                                }} value={uploadCondition} options={uploadConditions} />
                                <Messages position='top-center' ref={msgs} />
                            </div>
                            <div className='flex flex-column w-full p-2 gap-2 surface-0 fixed bottom-0 border-x-none border-bottom-none border-top-1 border-300'>
                                <div className='p-inputgroup'>
                                    <InputText onChange={(e)=>setUploadCustomCondition(e.target.value)} value={uploadCustomCondition} placeholder='Custom' />
                                    <Button severity='info'  label='Use Custom' onClick={()=>{
                                        if (uploadCustomCondition) {
                                            setUploadCondition(uploadCustomCondition);
                                            showMsg('success', null, uploadCustomCondition);
                                            setUploadStep(uploadStep+1)
                                        }else{
                                            showMsg('error', 'ERROR', 'Custom Condition Is Empty!')
                                        }
                                    }} />
                                </div>
                                <Button severity='danger' onClick={()=>{setUploadStep(uploadStep-1)}} label='Back' icon="pi pi-arrow-left" />
                            </div>
                        </div>
                    }
                    {
                        uploadStep === 3 &&
                        <div>
                            <div className='p-2 flex flex-column align-items-center'>
                                <h1 className='text-lg'>Choose Anything  The Lot Is Missing.</h1>
                                <ListBox multiple className='w-full' onChange={(e)=>{setUploadMissing(e.value)}} value={uploadMissing} options={uploadMissings} />
                                <Messages position='top-center' ref={msgs} />
                            </div>
                            
                            <div className='flex flex-column w-full p-2 gap-2 fixed bottom-0 border-x-none border-bottom-none border-top-1 border-300'>
                                <div className='p-inputgroup'>
                                    <InputText onChange={(e)=>setUploadCustomMissing(e.target.value)} value={uploadCustomMissing} placeholder='Custom' />
                                    <Button severity='info'  label='Add' onClick={()=>{
                                        if (uploadCustomMissing) {
                                            setUploadMissing([...uploadMissing, uploadCustomMissing]);
                                            setUploadMissings([...uploadMissings, {label: uploadCustomMissing, value: uploadCustomMissing}]);
                                            setUploadCustomMissing(null);
                                        }else{
                                            showMsg('error', 'ERROR', 'Custom Missing Is Empty!')
                                        }
                                    }} />
                                </div>
                                <Button severity='danger' onClick={()=>{setUploadStep(uploadStep-1)}} label='Back' icon="pi pi-arrow-left" />
                                {
                                    !uploadMissing.length ?
                                        <Button severity={'info'} onClick={()=>{setUploadStep(uploadStep+1); showMsg('success', null, 'Missing Nothing')}} label={'Missing Nothing'} />
                                    :
                                        <Button severity={'success'} onClick={()=>{setUploadStep(uploadStep+1); showMsg('success', null, 'Missing '+( uploadMissing.length>1?uploadMissing.length+'Things':uploadMissing[0]))}} label={'Continue'} />
                                }
                                
                            </div>
                        </div>
                    }
                    {
                        uploadStep === 4 &&
                        <div>
                            <div className='p-2 flex flex-column align-items-center'>
                                <h1 className='text-lg'>Choose The Lots Testing Status.</h1>
                                <ListBox className='w-full' onChange={(e)=>{
                                    if (!e.value) {
                                        setUploadStatus(null);
                                    }else{
                                        setUploadStatus(e.value);
                                        showMsg('success', null, e.value);
                                        setUploadStep(uploadStep+1)
                                    }
                                    

                                }} value={uploadStatus} options={uploadStatuses} />
                                <Messages position='top-center' ref={msgs} />
                            </div>
                            <div className='flex flex-column w-full p-2 gap-2 fixed surface-0 bottom-0 border-x-none border-bottom-none border-top-1 border-300'>
                                <div className='p-inputgroup'>
                                    <InputText onChange={(e)=>setUploadCustomStatus(e.target.value)} value={uploadCustomStatus} placeholder='Custom' />
                                    <Button severity='info'  label='Use Custom' onClick={()=>{
                                        if (uploadCustomStatus) {
                                            setUploadStatus(uploadCustomStatus);
                                            showMsg('success', null, uploadCustomStatus);
                                            setUploadStep(uploadStep+1)
                                        }else{
                                            showMsg('error', 'ERROR', 'Custom Condition Is Empty!')
                                        }
                                    }} />
                                </div>
                                <Button severity='danger' onClick={()=>{setUploadStep(uploadStep-1)}} label='Back' icon="pi pi-arrow-left" />
                            </div>
                        </div>
                    }
                    {
                        uploadStep === 5 &&
                        <form 
                            onSubmit={(e)=>{
                                e.preventDefault(); 
                                
                                if (parseLot(uploadLotNumber)) {
                                    showMsg('success')
                                    setUploadLotNumber(parseLot(uploadLotNumber, setUploadConsignor))
                                    setUploadStep(uploadStep+1);
                                }else{
                                    showMsg('error', 'ERROR', 'Invalid Lot Number' )
                                    setUploadLotNumber('');
                                }
                            }} 
                            className='flex flex-column align-items-center justify-content-between gap-5 p-2 pt-5 w-full h-full'
                        >
                            <div className='w-full flex flex-column align-items-center'>
                                <h1 className='text-lg'>Enter Lot Number</h1>
                                <div className='p-inputgroup'>
                                    <InputText id="lotInput" value={uploadLotNumber} onChange={(e)=>setUploadLotNumber(e.target.value)} label='Enter Lot Number (lot-000000 or 000000)' placeholder='Lot Number' />
                                    <Button onClick={()=>{setUploadLotNumber(''); setUpdate(!update)}} severity='danger' type="button" icon='pi pi-times' />
                                </div>
                                <Messages position='top-center' ref={msgs} />
                            </div>
                            
                            <div className='flex flex-column w-full p-2 gap-2 fixed surface-0 bottom-0 border-x-none border-bottom-none border-top-1 border-300'>
                                <Button severity='danger' type='button' onClick={()=>{setUploadStep(uploadStep-1)}} label='Back' icon="pi pi-arrow-left" />
                                <Button className='w-full' label="Continue" type='submit' />
                            </div>
                        </form>
                    }
                    {
                        uploadStep === 6 &&
                        <div className='flex flex-column align-items-center justify-content-between p-2 w-full h-full'>
                            <div className='overflow-scroll flex flex-column gap-2 w-full align-items-center'>
                                <h1 className='text-lg'>Enter or Search Barcode Below{uploadConsignor}</h1>
                                <form 
                                    onSubmit={(e)=>{
                                        e.preventDefault(); 
                                        
                                        if (uploadUpc.length > 3) {
                                            getUpc(uploadUpc, true);
                                            setUploadStep(1);
                                        }else{
                                            showMsg('error', 'ERROR', 'Invalid Barcode' )
                                        }
                                    }} 
                                    className='p-inputgroup'>
                                    <InputText id="upcInput" value={uploadUpc} onChange={(e)=>setUploadUpc(e.target.value)} label='Enter Barcode' placeholder='Barcode' />
                                    <Button onClick={()=>{setUploadUpc(''); setUpdate(!update)}} severity='danger' type="button" icon='pi pi-times' />
                                </form>
                            </div>
                            
                            <div className='flex flex-column w-full p-2 gap-2 surface-0 fixed bottom-0 border-x-none border-bottom-none border-top-1 border-300'>
                                <Messages position='bottom-center' ref={msgs} />
                                <Button severity='danger' type='button' onClick={()=>{setUploadStep(uploadStep-1)}} label='Back' icon="pi pi-arrow-left" />
                                <div className='p-inputgroup'>
                                    <Button className='w-full' severity='warning' label="Skip" type='submit' onClick={(e)=>{e.preventDefault(); setUploadFinalImages(uploadImages); setUploadStep(uploadStep+1)}} />
                                    <Button className='w-full' label="Continue" type='submit' onClick={(e)=>{
                                            e.preventDefault(); 
                                            
                                            if (uploadUpc.length > 3) {
                                                getUpc(uploadUpc, true);
                                                setUploadStep(1);
                                            }else{
                                                showMsg('error', 'ERROR', 'Invalid Barcode' )
                                                setUploadUpc('');
                                            }
                                        }} />
                                </div>
                                
                            </div>
                            
                        </div>
                    }
                    {
                        uploadStep === 7 &&
                        <>
                            {
                                uploadStockImages.length == 0 ? // IF THERE IS NO UPC DATA FOUND, use the same page but only have a field for the model quantity notes and category
                                <div className='overflow-scroll'>
                                    <div className='flex flex-column gap-2 p-2'>
                                        <div className='p-inputgroup'>
                                            <InputNumber onChange={(e)=>{setUploadQuantity(e.value);}} value={uploadQuantity} placeholder='Quantity' className={uploadQuantity < 1 ? 'p-invalid' : ''} />
                                            <InputText onChange={(e)=>setUploadModel(e.target.value)} value={uploadModel} placeholder='Model' className={!uploadModel && 'p-invalid'} />
                                        </div>
                                        <InputTextarea onChange={(e)=>setUploadDescription(e.target.value)} value={uploadDescription} placeholder='Notes / Short Description'  className={'h-10rem'} />
                                        
                                    </div>
                                    <div className='flex flex-column w-full p-2 gap-2 surface-0 absolute bottom-0 border-x-none border-bottom-none border-top-1 border-300'>
                                        <Messages position='bottom-center' ref={msgs} />
                                        <Button severity='danger' type='button' onClick={()=>{setUploadStep(uploadStep-1)}} label='Back' icon="pi pi-arrow-left" />-
                                        <Button className='w-full' severity='success' label="Submit" type='submit' onClick={()=>createLot(true, true)} />
                                        
                                    </div>
                                </div>
                                    
                                

                                :
                                <div className='overflow-scroll'>
                                    <div className='flex flex-column gap-2 p-2'>
                                        <div className='p-inputgroup'>
                                            <InputNumber onChange={(e)=>{setUploadQuantity(e.value);}} value={uploadQuantity} placeholder='Quantity' className={uploadQuantity < 1 ? 'p-invalid' : ''} />
                                            <InputText onChange={(e)=>setUploadModel(e.target.value)} value={uploadModel} placeholder='Model' className={!uploadModel && 'p-invalid'} />
                                            {/* <InputText onChange={(e)=>setUploadBrand(e.target.value)} value={uploadBrand} placeholder='Brand' className={!uploadBrand && 'p-invalid'} /> */}
                                        </div>
                                        <InputText onChange={(e)=>setUploadTitle(e.target.value)} value={uploadTitle} placeholder='Title' className={!uploadTitle && 'p-invalid'} />
                                        <InputTextarea onChange={(e)=>setUploadDescription(e.target.value)} value={uploadDescription} placeholder='Description'  className={!uploadDescription ? 'p-invalid h-10rem' : 'h-10rem'} />
                                        <Dropdown 
                                            value={uploadCategory}
                                            onChange={(e)=>{setUploadCategory(e.value)}}
                                            options={uploadCategories} 
                                            optionLabel="label"
                                            optionGroupLabel="label"
                                            optionGroupChildren="items" 
                                            placeholder='Category'    
                                            className={!uploadCategory && 'p-invalid'}
                                        />
                                    </div>
                                    <div className='text-sm pl-2'>Image Count: {uploadFinalImages.length}</div>
                                    <div className='flex gap-2 px-2 align-items-center pb-4 pt-2 overflow-scroll'>
                                        
                                        {
                                            uploadFinalImages.map((image, index) => {
                                                return <img
                                                    style={{minWidth: '6rem'}} 
                                                    className={'max-h-6rem border-green-200 border-round '+(index===0?' border-solid':'shadow-3')}
                                                    onTouchStart={(e)=>handleStart(e, uploadFinalImages, setUploadFinalImages)}
                                                    onTouchEnd={(e)=>handleEnd(e, uploadFinalImages, setUploadFinalImages, index)}
                                                    onMouseDown={(e)=>handleStart(e, uploadFinalImages, setUploadFinalImages)}
                                                    onMouseUp={(e)=>handleEnd(e, uploadFinalImages, setUploadFinalImages, index)}
                                                    key={index}
                                                    src={image} 
                                                    alt='Uploaded Image' 
                                                />
                                            })
                                        }
                                    </div>
                                    <div className='h-20rem'></div>
                                    <div className='flex flex-column w-full p-2 gap-2 surface-0 absolute bottom-0 border-x-none border-bottom-none border-top-1 border-300'>
                                        <Messages position='bottom-center' ref={msgs} />
                                        <Button severity='danger' type='button' onClick={()=>{setUploadStep(uploadStep-1)}} label='Back' icon="pi pi-arrow-left" />
                                        {
                                            uploadStockImages.length > 0 ? // auto flag if there are no stock images to upload
                                            <div className='p-inputgroup'>
                                                <Button className='w-full' severity='warning' label="Flag Submit" type='submit' onClick={()=>createLot(true)} />
                                                <Button className='w-full' severity='success' label="Submit" type='submit' onClick={()=>createLot(false)} />
                                            </div>
                                            :
                                            <Button className='w-full' severity='success' label="Submit" type='submit' onClick={()=>createLot(true)} />
                                        }
                                        
                                    </div>
                                </div>

                            }
                        </>
                        
                    }
                    {
                        // optional locate step depending on current users location
                        uploadStep === 8 &&
                        <>
                            {
                            locateDimPage ?
                            <div className='gap-2 flex flex-column w-full p-2'>
                                <form className='p-inputgroup' onSubmit={(e)=>{
                                    e.preventDefault();
                                    if (useLib.parseDim(locateDimHeight)) {
                                        setLocateDimHeight(useLib.parseDim(locateDimHeight));
                                        //focus next input
                                        document.getElementById('locateDimWidth').focus();
                                    }else{
                                        showMsg('error', null, 'Invalid Dim Code!')
                                        setLocateDimHeight('');
                                    }
                                }}>
                                    <InputText onChange={(e)=>setLocateDimHeight(e.target.value)} value={locateDimHeight} autoFocus placeholder='Height (Inches)'></InputText>
                                    <Button type='submit' icon='pi pi-send' />
                                </form>

                                <form className='p-inputgroup mt-2' onSubmit={(e)=>{
                                    e.preventDefault();
                                    if (useLib.parseDim(locateDimWidth)) {
                                        setLocateDimWidth(useLib.parseDim(locateDimWidth));
                                        //focus next input
                                        document.getElementById('locateDimLength').focus();
                                    }else{
                                        showMsg('error', null, 'Invalid Dim Code!')
                                        setLocateDimWidth('');
                                    }
                                }}>
                                    <InputText onChange={(e)=>setLocateDimWidth(e.target.value)} value={locateDimWidth} id='locateDimWidth' placeholder='Width (Inches)'></InputText>
                                    <Button type='submit' icon='pi pi-send' />
                                </form>

                                <form className='p-inputgroup mt-2' onSubmit={(e)=>{
                                    e.preventDefault();
                                    if (useLib.parseDim(locateDimLength)) {
                                        setLocateDimLength(useLib.parseDim(locateDimLength));
                                        //focus next input
                                        document.getElementById('locateDimWeight').focus();
                                    }else{
                                        showMsg('error', null, 'Invalid Dim Code!')
                                        setLocateDimLength('');
                                    }
                                }}>
                                    <InputText onChange={(e)=>setLocateDimLength(e.target.value)} value={locateDimLength} id='locateDimLength' placeholder='Length (Inches)'></InputText>
                                    <Button type='submit' icon='pi pi-send' />
                                </form>

                                <form className='p-inputgroup mt-2' onSubmit={(e)=>{
                                    e.preventDefault();
                                    if (useLib.parseWgt(locateDimWeight)) {
                                        setLocateDimWeight(useLib.parseWgt(locateDimWeight));
                                        //focus next input
                                    }else{
                                        showMsg('error', null, 'Invalid Weight Code!')
                                        setLocateDimWeight('');
                                    }
                                }}>
                                    <InputText onChange={(e)=>setLocateDimWeight(e.target.value)} value={locateDimWeight} id='locateDimWeight' placeholder='Weight (Pounds)'></InputText>
                                    <Button type='submit' icon='pi pi-send' />
                                </form>
                                <div  className='mt-2 p-inputgroup'>
                                    <Button severity='danger' label='Skip Measurement' onClick={()=>{
                                        setLocateDimPage(false);
                                        setLocateDimHeight('');
                                        setLocateDimWidth('');
                                        setLocateDimLength('');
                                        setLocateDimWeight('');
                                    }} />
                                    <Button severity='success' onClick={()=>{
                                        if (useLib.parseDim('dim-'+locateDimHeight) && useLib.parseDim('dim-'+locateDimWidth) && useLib.parseDim('dim-'+locateDimLength) && useLib.parseWgt('wgt-'+locateDimWeight)) {
                                            setLocateDimPage(false);
                                            showMsg('success', null, 'Measurements Added!')
                                            // focuse the location number input
                                        }else{
                                            showMsg('error', null, 'Invalid Measurement Values!')
                                        }
                                    }} label='Continue' />
                                </div>
                                

                            </div>
                            :
                            <div>
                                <form className='p-inputgroup w-ful p-2' onSubmit={()=>{
                                    if (useLib.parseLoc(uploadLocation)) {
                                        locateLot(uploadLotNumber, uploadLocation, "Lot Located", {weight: locateDimWeight, height: locateDimHeight, width: locateDimWidth, length: locateDimLength});
                                        setLocateDimWidth('');
                                        setLocateDimHeight('');
                                        setLocateDimLength('');
                                        setLocateDimWeight('');
                                        setUploadStep(1); 
                                        uploadWipe()
                                    }else{
                                        showMsg('error', null, 'Invalid Input!')
                                        setUploadLocation('');
                                    }
                                    
                                }}>
                                    <InputText autoFocus={true} onChange={(e)=>setUploadLocation(e.target.value)} value={uploadLocation} placeholder='Location' />
                                    <Button icon='pi-map-marker' type='submit' severity='success' />
                                </form>
                                <div className='flex flex-column w-full p-2 gap-2 surface-0 absolute bottom-0 border-x-none border-bottom-none border-top-1 border-300'>
                                    <Messages position='bottom-center' ref={msgs} />
                                    <Button className='w-full' severity='warning' label="Skip Locating" type='button' onClick={()=>{setUploadStep(1); uploadWipe()}} />
                                    
                                </div>
                            </div>
                            }

                        </>
                        
                    }
                </div>
            }
            {/* Locate Page //locate */}
            {
                page === 3 &&
                    <div className='gap-2 flex flex-column h-full w-full p-2 '>
                        {
                        !locateDimPage &&
                            <form className='p-inputgroup' onSubmit={(e)=>{
                                e.preventDefault();
                                if (locateInput) { // check that there is a value in the location input
                                    
                                    if (useLib.parseLoc(locateInput)) { // check if value is location number
                                        if (locateLotNumber) {
                                            locateLot(locateLotNumber, locateInput, "Lot Located", {weight: locateDimWeight, height: locateDimHeight, width: locateDimWidth, length: locateDimLength});
                                            setLocatePrevious([{lotNumber: locateLotNumber, locationNumber: locateInput}, ...locatePrevious]);
                                            setLocateInput('');
                                            setLocateLotNumber('');
                                            setLocateDimHeight('');
                                            setLocateDimWidth('');
                                            setLocateDimLength('');
                                            setLocateDimWeight('');
                                            return
                                        }else{
                                            setLocateInput('');
                                            return showMsg('error', null, 'Lot Number First!')
                                        }
                                    }
                                    if (useLib.parseLot(locateInput) !== false) { // check if value is lot number
                                        setLocateLotNumber(useLib.parseLot(locateInput))
                                        setLocateDimPage(true);
                                        showMsg('success')
                                        setLocateInput('');
                                    }else{
                                        setLocateInput('');
                                        return showMsg('error', null, 'Invalid Input!')
                                    }
                                }else{
                                    return showMsg('error', null, 'No Input!')
                                }
                                
                            }}>
                                <Button type='button' icon="pi pi-arrow-left" severity='danger' className="p-button-info" onClick={()=>{setPage(1); setLocateInput(''); setLocateLotNumber(''); setLocatePrevious([])}} />
                                <InputText autoFocus={true} value={locateInput} onChange={(e)=>{setLocateInput(e.target.value);}} placeholder={locateLotNumber?"Enter Location-Number":"Enter Lot-Number"} />
                                <Button type='submit' icon="pi pi-search" className="p-button-info"/>
                            </form>
                        }
                        {!locateDimPage ?
                            <>
                                <Button label='Pallet Info' onClick={()=>setLocatePalletCheckDialog(true)} />
                                <div className={locateLotNumber?"p-2 border-round flex flex-column gap-2 border-solid border-1 ":"p-skeleton p-2 border-round flex flex-column gap-2"}>
                                    <span className='text-xl'>Lot-Number: {locateLotNumber?locateLotNumber:"Waiting"}</span>
                                </div> 
                                <div className={"p-skeleton text-xl  p-2 border-round flex flex-column gap-2"}>
                                    <span className='text-xl'>Location Number: Waiting</span>
                                </div> 
                                <div className='flex flex-column gap-2'>
                                    {
                                        locatePrevious.map((lot, index) => {
                                            return (
                                                <div className='p-2 border-round flex flex-column gap-2 border-solid border-1'>
                                                    <span className='text-xl'>Lot-Number: {lot.lotNumber}</span>
                                                    <span className='text-xl'>Location Number: {lot.locationNumber}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        :
                            <div className='gap-2 flex flex-column w-full p-2'>
                                <form className='p-inputgroup' onSubmit={(e)=>{
                                    e.preventDefault();
                                    if (useLib.parseDim(locateDimHeight)) {
                                        setLocateDimHeight(useLib.parseDim(locateDimHeight));
                                        //focus next input
                                        document.getElementById('locateDimWidth').focus();
                                    }else{
                                        showMsg('error', null, 'Invalid Dim Code!')
                                        setLocateDimHeight('');
                                    }
                                }}>
                                    <InputText onChange={(e)=>setLocateDimHeight(e.target.value)} value={locateDimHeight} autoFocus placeholder='Height (Inches)'></InputText>
                                    <Button type='submit' icon='pi pi-send' />
                                </form>

                                <form className='p-inputgroup mt-2' onSubmit={(e)=>{
                                    e.preventDefault();
                                    if (useLib.parseDim(locateDimWidth)) {
                                        setLocateDimWidth(useLib.parseDim(locateDimWidth));
                                        //focus next input
                                        document.getElementById('locateDimLength').focus();
                                    }else{
                                        showMsg('error', null, 'Invalid Dim Code!')
                                        setLocateDimWidth('');
                                    }
                                }}>
                                    <InputText onChange={(e)=>setLocateDimWidth(e.target.value)} value={locateDimWidth} id='locateDimWidth' placeholder='Width (Inches)'></InputText>
                                    <Button type='submit' icon='pi pi-send' />
                                </form>

                                <form className='p-inputgroup mt-2' onSubmit={(e)=>{
                                    e.preventDefault();
                                    if (useLib.parseDim(locateDimLength)) {
                                        setLocateDimLength(useLib.parseDim(locateDimLength));
                                        //focus next input
                                        document.getElementById('locateDimWeight').focus();
                                    }else{
                                        showMsg('error', null, 'Invalid Dim Code!')
                                        setLocateDimLength('');
                                    }
                                }}>
                                    <InputText onChange={(e)=>setLocateDimLength(e.target.value)} value={locateDimLength} id='locateDimLength' placeholder='Length (Inches)'></InputText>
                                    <Button type='submit' icon='pi pi-send' />
                                </form>

                                <form className='p-inputgroup mt-2' onSubmit={(e)=>{
                                    e.preventDefault();
                                    if (useLib.parseWgt(locateDimWeight)) {
                                        setLocateDimWeight(useLib.parseWgt(locateDimWeight));
                                        //focus next input
                                    }else{
                                        showMsg('error', null, 'Invalid Weight Code!')
                                        setLocateDimWeight('');
                                    }
                                }}>
                                    <InputText onChange={(e)=>setLocateDimWeight(e.target.value)} value={locateDimWeight} id='locateDimWeight' placeholder='Weight (Pounds)'></InputText>
                                    <Button type='submit' icon='pi pi-send' />
                                </form>
                                <div  className='mt-2 p-inputgroup'>
                                    <Button severity='danger' label='Skip Measurement' onClick={()=>{
                                        setLocateDimPage(false);
                                        setLocateDimHeight('');
                                        setLocateDimWidth('');
                                        setLocateDimLength('');
                                        setLocateDimWeight('');
                                    }} />
                                    <Button severity='success' onClick={()=>{
                                        if (useLib.parseDim('dim-'+locateDimHeight) && useLib.parseDim('dim-'+locateDimWidth) && useLib.parseDim('dim-'+locateDimLength) && useLib.parseWgt('wgt-'+locateDimWeight)) {
                                            setLocateDimPage(false);
                                            showMsg('success', null, 'Measurements Added!')
                                            // focuse the location number input
                                        }else{
                                            showMsg('error', null, 'Invalid Measurement Values!')
                                        }
                                    }} label='Continue' />
                                </div>
                                

                            </div>
                                
                            
                        }
                    </div>
                        
            }
            {/* Orders Page */}
            {
                page === 4 &&
                <div className='flex flex-column'>
                    {
                        orders.map((order, index) => {
                            return (
                                <div className='flex flex-column gap-2 p-2 border-solid border-1 border-round'>
                                    <span className='text-xl'>Order Total: ${(order.orderTotal/100).toFixed(2)}</span>
                                    <span className='text-xl'>Customer Name: {order.details.customer_details.name}</span>
                                </div>
                            )
                        
                        })
                    }
                </div>
            }
            {/* View/Edit Page */}
            {
                page === 5 &&
                <div className='flex flex-column w-screen gap-2 pt-2 overflow-y-scroll'>
                    <Dialog header="List Item On Store (WIP)" visible={viewEditListingDialog} style={{ width: '70vw' }} onHide={()=>setViewEditListingDialog(!viewEditListingDialog)}>
                        <div className='flex flex-column p-4 gap-4'>
                            <span className="flex flex-column">
                                <label>Dimensions (Click To Copy) use CTRL+V to paste into shipping software and get estimate</label>
                                <div className='flex gap-2'>
                                    <Button label={viewEditLot.height ? viewEditLot.height+'"' : 'None' } onClick={()=>{navigator.clipboard.writeText(viewEditLot.height)}} />
                                    <Button label={viewEditLot.height ? viewEditLot.width+'"' : 'None' } onClick={()=>{navigator.clipboard.writeText(viewEditLot.width)}} />
                                    <Button label={viewEditLot.height ? viewEditLot.length+'"' : 'None' } onClick={()=>{navigator.clipboard.writeText(viewEditLot.length)}} />
                                    <Button label={viewEditLot.height ? viewEditLot.weight+'lbs' : 'None' } onClick={()=>{navigator.clipboard.writeText(viewEditLot.weight)}} />
                                </div>
                            </span>
                            <span className="flex flex-column">
                                <label>Sale Price</label>
                                {/* only allow numbers and decimals in this inputtext */}
                                <InputText prefix='$' value={viewEditPrice} onChange={(e)=>setViewEditPrice(e.target.value ? parseFloat(e.target.value): e.target.value)} keyfilter="money" />
                            </span>
                            <span className="flex flex-column">
                                <label>Shipping Price (Leave Empty for pick-up only</label>
                                <InputText value={viewEditShipping} onChange={(e)=>setViewEditShipping(e.target.value ? parseFloat(e.target.value): e.target.value)} keyfilter="money" />
                            </span>
                            <span>Total: {viewEditPrice + viewEditShipping}</span>
                            <Button label='List Lot' onClick={()=>{
                                axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/listlot', {platform: 'store', lotNumber: viewEditLot.lotNumber, price: viewEditPrice*100, shipping: viewEditShipping*100}).then((res)=>{
                                    showMsg('success', null, 'Lot Listed!');
                                }).catch((err)=>{
                                    console.log(err);
                                    showMsg('error', null, 'Something Went Wrong!');
                                })
                            }} />
                            <Button severity='warning' label="Mark Listed eBay (Don't Miss Click This)" onClick={()=>{
                                axios.post('https://fivedollarauctionsserver-fc8b9c6b821d.herokuapp.com/api/lot/listlot', {platform: 'ebay', lotNumber: viewEditLot.lotNumber}).then((res)=>{
                                    showMsg('success', null, 'Lot Marked Listed!');
                                }).catch((err)=>{
                                    console.log(err);
                                    showMsg('error', null, 'Something Went Wrong!');
                                })
                            }} />
                        </div>
                        
                    </Dialog>
                    {
                        typeof(viewEditPage) == 'number' ?
                        <div className='w-full flex flex-column gap-4 px-2'>
                            {
                                windowSize[0] > 600 &&
                                <div className='w-full justify-content-between flex'>
                                    <Button icon='pi pi-arrow-left' severity='danger' onClick={()=>{searchlot(''); setViewEditPage(false)}}></Button>
                                    <Button icon='pi pi-arrow-up' severity='info' onClick={()=>setViewEditListingDialog(!viewEditListingDialog)}  />
                                    <ToggleButton className={(viewEditFlagged ? ' bg-red-400 border-none' : ' bg-blue-500 text-white border-none')} checked={viewEditFlagged} onChange={(e) => setViewEditFlagged(e.value)} offIcon='pi' onIcon="pi pi-flag" onLabel="Flagged" offLabel="Flag" />
                                    <Button severity='success' icon='pi pi-arrow-right' iconPos='right' label='Apply Changes' onClick={()=>{updateLot()}} />
                                </div>
                            }
                            <span className='p-float-label flex gap-2'>
                                <span>Lot Number: {viewEditLotNumber}</span>
                                <span>Batch Number: {viewEditConsignor}</span>
                                <span>Uploader: {viewEditUploader}</span>
                                <a onClick={()=>{navigator.clipboard.writeText(viewEditConsignor + " : " + viewEditLotNumber + " - " + viewEditLocation); alert("Copied!")}} style={{cursor: 'pointer', textDecoration: 'underline'}}>Copy</a>
                            </span>
                            <span className='p-float-label mt-3'>
                                <InputText className={'w-full'+(!viewEditPallet ? ' p-invalid':'')} value={viewEditPallet} onChange={(e)=>setViewEditPallet(e.target.value)} />
                                <label>Pallet Number</label>
                            </span>
                            <span className='p-float-label'>
                                <InputText className={'w-full'+(!viewEditPallet ? ' p-invalid':'')} value={viewEditLocation} onChange={(e)=>setViewEditLocation(e.target.value)} />
                                <label>Location Number</label>
                            </span>
                            <div className='flex justify-content-between w-full gap-2'>
                                <span className='p-float-label w-full' style={{width: '50% !important'}}>
                                    <InputText className={'w-full'+(!viewEditBrand ? ' p-invalid':'')} style={{width: '100% !important'}} value={viewEditBrand} onChange={(e)=>setViewEditBrand(e.target.value)} />
                                    <label>Brand</label>
                                </span>
                                <span className=' p-float-label'>
                                    <InputNumber className={'w-full'+(!viewEditQuantity ? ' p-invalid':'')} value={viewEditQuantity} onChange={(e)=>setViewEditQuantity(e.value)} />
                                    <label>Quantity</label>
                                </span>
                                
                            </div>
                            <span className='p-float-label'>
                                <InputText className={'w-full'+(!viewEditTitle ? ' p-invalid':'')} value={viewEditTitle} onChange={(e)=>setViewEditTitle(e.target.value)} />
                                <label>Title</label>
                            </span>
                            <span className='p-float-label'>
                                <InputTextarea className={'w-full'+(!viewEditDescription ? ' p-invalid':'')} value={viewEditDescription} onChange={(e)=>setViewEditDescription(e.target.value)} />
                                <label>Description</label>
                            </span>
                            <div className='flex justify-content-between w-full gap-2'>
                                <span className='p-float-label w-full' style={{width: '50% !important'}}>
                                    <InputText className={'w-full'+(!viewEditModel ? ' p-invalid':'')} style={{width: '100% !important'}} value={viewEditModel} onChange={(e)=>setViewEditModel(e.target.value)} />
                                    <label>Model</label>
                                </span>
                                <span className='p-float-label' style={{width: '50% !important'}}>
                                    <InputText className={'w-full'+(!viewEditColor ? ' p-invalid':'')} style={{width: '100% !important'}} value={viewEditColor} onChange={(e)=>setViewEditColor(e.target.value)} />
                                    <label>Color</label>
                                </span>
                            </div>
                            
                            
                            {windowSize[0] > 600 ?
                                <>
                                    <div className='flex gap-2 align-items-center overflow-x-scroll'>
                                        {
                                            viewEditImages.map((image, index) => {
                                                return (
                                                    <div className='flex flex-column'>
                                                        { image &&
                                                            <img 
                                                                className={'border-green-200 h-10rem '+(index===0?' border-solid':'shadow-3')+(windowSize[0] > 600 ? ' border-noround-bottom border-round-top' : ' border-round')}
                                                                onTouchStart={(e)=>handleStart(e, viewEditImages, setViewEditImages)}
                                                                onTouchEnd={(e)=>handleEnd(e, viewEditImages, setViewEditImages, index)}
                                                                onMouseDown={(e)=>handleStart(e, viewEditImages, setViewEditImages)}
                                                                onMouseUp={(e)=>handleEnd(e, viewEditImages, setViewEditImages, index)}
                                                                key={index}
                                                                src={image} 
                                                                alt='Uploaded Image' 
                                                            />
                                                        }
                                                        <div className='p-inputgroup'>
                                                            <Button className='w-full border-noround-top' onClick={()=>{setViewEditImages([...viewEditImages].filter((img, i)=>i!==index))}} severity='danger' icon='pi pi-times' />
                                                            <Button className='w-full border-noround-top' onClick={()=>{setViewEditImages(viewEditImages => [viewEditImages[index], ...viewEditImages.slice(0, index), ...viewEditImages.slice(index+1)]);}} severity='info' icon='pi pi-arrow-up' />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <FileUploader
                                        multiple={true}
                                        handleChange={(e)=>{
                                            handleFileDrop(e)
                                        }}
                                        name="file"
                                        types={["JPEG", "PNG", "GIF", "JPG", "WEBP", "AVIF"]}
                                    />
                                </>
                                :
                                <FileUploader
                                    multiple={true}
                                    handleChange={(e)=>{
                                        handleFileDrop(e)
                                    }}
                                    name="file"
                                    types={["JPEG", "PNG", "GIF", "JPG", "WEBP"]}
                                />

                            }
                            
                            
                            
                            
                            
                            
                            <span className='p-float-label'>
                                <InputText className={'w-full'+(!viewEditCondition ? ' p-invalid':'')} value={viewEditCondition} onChange={(e)=>setViewEditCondition(e.target.value)} />
                                <label>Condition</label>
                            </span>
                            <span className='p-float-label'>
                                <InputText className={'w-full'+(!viewEditStatus ? ' p-invalid':'')} value={viewEditStatus} onChange={(e)=>setViewEditStatus(e.target.value)} />
                                <label>Status</label>
                            </span>
                            <span>
                                <div className='p-inputgroup'>
                                    <InputText className='w-full border-noround-bottom' value={viewEditMissingCustom} placeholder='Missing' onChange={(e)=>setViewEditMissingCustom(e.target.value)} />
                                    <Button icon='pi pi-arrow-right' className='border-noround-bottom' severity='info' onClick={()=>{setViewEditMissingOptions([viewEditMissingCustom, ...viewEditMissingOptions]); setViewEditMissing([viewEditMissingCustom, ...viewEditMissing]); setViewEditMissingCustom('')}}/>
                                </div>
                                <ListBox
                                    className='border-noround-top'
                                    multiple
                                    value={viewEditMissing}
                                    options={viewEditMissingOptions}
                                    onChange={(e)=>setViewEditMissing(e.value)}
                                />
                                
                                
                                
                            </span>
                            {windowSize[0] < 600 &&
                                <div className='h-15rem'>

                                </div>
                            }
                            {windowSize[0] < 600 &&
                                <div className='fixed gap-2 bottom-0 left-0 w-full shadow-4 bg-white p-2 flex flex-column'>
                                    <div className='flex gap-2 align-items-center w-full overflow-x-scroll'>
                                        {
                                            viewEditImages.map((image, index) => {
                                                return (
                                                    <div className='flex flex-column'>
                                                    { image && 
                                                        <img 
                                                            className={'border-green-200 h-7rem '+(index===0?' border-solid':'shadow-3')+(windowSize[0] > 600 ? ' border-noround-bottom border-round-top' : ' border-round')}
                                                            onTouchStart={(e)=>handleStart(e, viewEditImages, setViewEditImages)}
                                                            onTouchEnd={(e)=>handleEnd(e, viewEditImages, setViewEditImages, index)}
                                                            onMouseDown={(e)=>handleStart(e, viewEditImages, setViewEditImages)}
                                                            onMouseUp={(e)=>handleEnd(e, viewEditImages, setViewEditImages, index)}
                                                            key={index}
                                                            src={image} 
                                                            alt='Uploaded Image' 
                                                        />
                                                    }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='p-inputgroup'>
                                        <Button icon='pi pi-arrow-left' severity='danger' onClick={()=>{setViewEditPage(false)}}></Button>
                                        <ToggleButton className={(viewEditFlagged ? ' bg-red-400 border-none' : ' bg-blue-500 text-white border-none')} checked={viewEditFlagged} onChange={(e) => setViewEditFlagged(e.value)} offIcon='pi' onIcon="pi pi-flag" onLabel="Flagged" offLabel="Flag" />
                                        <Button severity='success' icon='pi pi-arrow-right' iconPos='right' label='Apply Changes' onClick={()=>{updateLot()}} />
                                    </div>
                                </div>
                            }

                        </div>
                        :
                        <>  
                            <div className='w-full gap-2 flex px-2'>
                                <Dialog header="Spreadsheet Import / Export" visible={viewImportDialog} className='mx-2 w-full' onHide={() => setViewImportDialog(false)}>
                                    <DataTable>
                                        <Column className='text-xs' field="lotNumber" header="Lot Number" />
                                        <Column className='text-xs' field="palletNumber" header="Pallet Number" />
                                        {/* <Column className='text-xs' field="locationNumber" header="Location Number" /> */}
                                        <Column className='text-xs' field="brand" header="Brand" />
                                        <Column className='text-xs' field="quantity" header="Quantity" />
                                        <Column className='text-xs' field="title" header="Title" />
                                        <Column className='text-xs' field="description" header="Description" />
                                        <Column className='text-xs' field="model" header="Model" />
                                        <Column className='text-xs' field="color" header="Color" />
                                        <Column className='text-xs' field="category" header="Category" />
                                        <Column className='text-xs' field="condition" header="Condition" />
                                        <Column className='text-xs' field="status" header="Status" />
                                        <Column className='text-xs' field="missing" header="Missing" />
                                        <Column className='text-xs' field="flagged" header="Flagged" />
                                        <Column className='text-xs' field="uploadedBy" header="Uploaded By" />
                                        {/* <Column className='text-xs' field="locatedBy" header="Located By" /> */}
                                        <Column className='text-xs' field="timeUploaded" header="Time Uploaded" />
                                        {/* <Column className='text-xs' field="timeLocated" header="Time Located" /> */}
                                        

                                    </DataTable>
                                </Dialog>
                                <form className='p-inputgroup' onSubmit={(e)=>{e.preventDefault(); searchlot(viewSearch)}}>
                                    <Button type='button' icon='pi pi-arrow-left' severity='danger' onClick={()=>{setPage(1); resetViewSearch()}} />
                                    <InputText onChange={(e)=>setViewSearch(e.target.value)} placeholder='Lot/Keyword Search' />
                                    <Button type='submit' icon='pi pi-search' severity='info' />
                                </form>
                                {   windowSize[0] > 600 &&
                                    <Button severity='info' label='Import/Export' onClick={()=>setViewImportDialog(true)}/>
                                }
                                
                            </div>
                            <Accordion className='px-2' >
                                <AccordionTab header="Filters">
                                    <div className='flex flex-column gap-4'>
                                        
                                        <form className="p-float-label flex w-full mt-3" onSubmit={(e)=>{
                                            e.preventDefault(); 
                                            if (/[^0-9]/.test(viewPalletSearch)) {
                                                if (useLib.parsePallet(viewPalletSearch)) {
                                                    setViewPalletSearch(useLib.parsePallet(viewPalletSearch))
                                                    setViewSortedLots([...viewLots].filter(lot => lot.palletNumber === useLib.parsePallet(viewPalletSearch)));   
                                                }else{
                                                    if (useLib.parseLoc(viewPalletSearch)) {
                                                        setViewPalletSearch(useLib.parseLoc(viewPalletSearch))
                                                        setViewSortedLots([...viewLots].filter(lot => lot.locationNumber === useLib.parseLoc(viewPalletSearch)));
                                                    }else{
                                                        setViewPalletSearch('');
                                                        showMsg('error', null, 'Invalid Pallet/Location Number' );
                                                        resetViewSearch()
                                                    }
                                                }
                                                
                                            }else{
                                                setViewPalletSearch('');
                                                showMsg('error', null, 'Invalid Pallet/Location Number' );
                                                resetViewSearch()
                                            }
                                            
                                                
                                        }}>
                                            <InputText value={viewPalletSearch} onChange={(e)=>{setViewPalletSearch(e.target.value); setUpdate(!update)}} className='w-full border-noround-right' />
                                            <Button type='submit' icon='pi pi-search' severity='info' className='border-noround-left'/>
                                            <label>Pallet / Location Code</label>
                                        </form>
                                        <span className="p-float-label">
                                            <Dropdown options={[
                                                {label: 'All', value: 'All'},
                                                {label: 'Flagged', value: 'flagged'},
                                                {label: 'Idle', value: 'idle'},
                                                {label: 'Located', value: 'located'},
                                                {label: 'Awaiting Location', value: 'awaiting'},
                                            ]} value={viewLotStatusSearch} onChange={(e)=>{
                                                setViewLotStatusSearch(e.value)
                                                if (e.value === 'All') {
                                                    setViewSortedLots(viewLots);
                                                    
                                                }
                                                if (e.value === 'located') {
                                                    console.log(viewLots)
                                                    setViewSortedLots([...viewLots].filter(lot => lot));
                                                }
                                                if (e.value === 'Awaiting Location') {
                                                    setViewSortedLots([...viewLots].filter(lot => !lot.locationNumber));
                                                }
                                                if (e.value === 'Flagged') {
                                                    setViewSortedLots([...viewLots].filter(lot => lot.flagged));
                                                }
                                            }} />
                                            <label>Lot Status</label>
                                        </span>
                                        <span className="p-float-label">
                                            <Dropdown className='w-full' options={[
                                                {label: "None", value: 'None'},
                                                {label: 'Upload Time Asc', value: 'Upload Time Asc'},
                                                {label: 'Upload Time Desc', value: 'Upload Time Desc'},
                                                {label: 'Locate Time Asc', value: 'Locate Time Asc'},
                                                {label: 'Locate Time Desc', value: 'Locate Time Desc'},
                                                {label: 'Last Updated Asc', value: 'Last Updated Asc'},
                                                {label: 'Last Updated Desc', value: 'Last Updated Desc'},
                                                {label: 'Lot Number Asc', value: 'Lot Number Asc'},
                                                {label: 'Lot Number Desc', value: 'Lot Number Desc'},
                                            ]} value={viewSort} onChange={(e)=>{
                                                setViewSort(e.value)
                                                if (e.value === 'None') {
                                                    setViewSortedLots(viewSortedLots);
                                                } else {
                                                    setViewSortedLots([...viewSortedLots].sort((a, b) => {
                                                        let atimeUploaded = Date.parse(a.timeUploaded);
                                                        let btimeUploaded = Date.parse(b.timeUploaded); 
                                                        let alastUpdated = Date.parse(a.lastUpdated);
                                                        let blastUpdated = Date.parse(b.lastUpdated);
                                                        let alocateTime = Date.parse(a.locateTime);
                                                        let blocateTime = Date.parse(b.locateTime);
                                                        if (e.value === 'Upload Time Asc') {
                                                            return atimeUploaded - btimeUploaded;
                                                        }else if (e.value === 'Upload Time Desc') {
                                                            return btimeUploaded - atimeUploaded;
                                                        }else if (e.value === 'Locate Time Asc') {
                                                            return alocateTime - blocateTime;
                                                        }else if (e.value === 'Locate Time Desc') {
                                                            return blocateTime - alocateTime;
                                                        }else if (e.value === 'Last Updated Asc') {
                                                            return alastUpdated - blastUpdated;
                                                        }else if (e.value === 'Last Updated Desc') {
                                                            return blastUpdated - alastUpdated;
                                                        }else if (e.value === 'Lot Number Asc') {
                                                            return a.lotNumber.replace('lot-', '') - b.lotNumber.replace('lot-', '');
                                                        }else if (e.value === 'Lot Number Desc') {
                                                            return b.lotNumber.replace('lot-', '') - a.lotNumber.replace('lot-', '');
                                                        }
                                                    }))
                                                }
                                            }} />
                                            <label>Sort By</label>
                                        </span>
                                
                                    </div>
                                </AccordionTab>
                            </Accordion>
                            
                            <div className='flex flex-column gap-2 px-2'>
                                {
                                    viewSortedLots.map((lot, i) => {
                                        return (
                                            <div 
                                                className={!lot.flagged? lot.dupe == false ? 'p-2 border-green-400 border-2 border-solid border-round flex flex-column' : 'p-2 border-300 border-1 border-solid border-round flex flex-column' : 'p-2 border-red-300 border-2 border-solid border-round flex flex-column'} 
                                                onClick={()=>{
                                                    setViewEditLot(lot);
                                                    setViewEditPage(i)
                                                    setViewEditUpc(lot.upc);
                                                    setViewEditFlagged(lot.flagged);
                                                    setWasFlagged(lot.flagged);
                                                    setViewEditLotNumber(lot.lotNumber);
                                                    setViewEditUploader(lot.uploadedBy);
                                                    setViewEditConsignor(lot.consignor);
                                                    setViewEditPallet(lot.palletNumber);
                                                    setViewEditLocation(lot.locationNumber);
                                                    setViewEditTitle(lot.title);
                                                    setViewEditDescription(lot.description);
                                                    setViewEditBrand(lot.brand);
                                                    setViewEditModel(lot.model);
                                                    setViewEditColor(lot.color);
                                                    setViewEditCategory(lot.category);
                                                    setViewEditQuantity(lot.quantity);
                                                    setViewEditImages(lot.images);
                                                    setViewEditCondition(lot.condition);
                                                    setViewEditMissing(lot.missing);
                                                    setViewEditMissingOptions(lot.missing);
                                                    setViewEditStatus(lot.status);
                                                    
                                                }
                                            }>

                                            
                                                <div className='flex gap-2 justify-content-between max-h-4rem'>
                                                    <div className='flex gap-2'>
                                                        <div className='h-full'>
                                                            { lot.images[0] && 
                                                                <img src={lot.images[0]}  style={{objectFit: 'contain'}} className='border-round w-full h-full'/>
                                                            }
                                                        </div>
                                                        
                                                        <div className='flex flex-column gap-1'>
                                                            <div className='text-sm'>{lot.lotNumber} -> {lot.palletNumber ? lot.palletNumber : lot.locationNumber}</div>
                                                            <div className='text-xs overflow-hidden'>{lot.title}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='flex gap-1'>
                                                    <div className='text-xs text-400'>Uploaded <Moment fromNow>{lot.timeUploaded}</Moment></div>
                                                    {
                                                        lot.locationNumber &&
                                                        <div className='text-xs text-400'>Located <Moment fromNow>{lot.timeLocated}</Moment></div>
                                                    }  
                                                </div>
                                                
                                            </div>
                                        )
                                        
                                    })
                                }
                                <Button label='Load More' onClick={()=>{setLotSearchResultCount(lotSearchResultCount+10); setUpdate(!update); searchlot(viewSearch) }} />
                            </div>
                        </>
                    }
                </div>
            }


        </div>
    )
}
